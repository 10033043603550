import { IPhoneNumber } from './types';

export const validatePhoneNumber = [
  // { required: true, message: '' },
  // {
  //   validator(_: any, value: IPhoneNumber) {
  //     if (value.dialCode?.length && value.number?.length) {
  //       return Promise.resolve();
  //     } else {
  //       return Promise.reject(new Error());
  //     }
  //   }, 
  //   message: 'Phone Number field is required.'
  // }, 
  {
    validator(_: any, value: IPhoneNumber) {
      if (value.number.length >= 8 && value.number.length <= 15 ) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error());
      }
    }, message: 'Phone Number must contain between 8 and 15 digits.'
  }];