import { Icons } from "@pankod/refine-antd";
import { DashboardPage } from "pages/dashboard";
import { Definitions } from "pages/definitions";
import { HomePage } from "pages/home";
import Insights from "pages/insights";
import {
  PatientCreate,
  PatientEdit,
  PatientList,
  PatientShow,
} from "pages/patients";
import SurveyFlowPage from "pages/surveyFlow";
import { UserManagement } from "pages/userManagement";

const patients = {
  name: "patients",
  list: PatientList,
  create: PatientCreate,
  edit: PatientEdit,
  show: PatientShow,
  icon: <Icons.MedicineBoxOutlined />,
};

const insights = {
  name: "insights",
  list: Insights,
  options: { label: "Insights" },
  icon: <Icons.EyeOutlined />,
};

const home = {
  name: "home",
  list: HomePage,
  title: "Home",
  options: { label: "Home" },
  icon: <Icons.HomeOutlined />,
};

const survey = {
  name: "survey",
  list: SurveyFlowPage,
  options: { label: "Survey Flow" },
  icon: <Icons.NodeCollapseOutlined />,
};

export const resources = (user: any) => {
  const groups =
    user?.signInUserSession?.accessToken?.payload?.["cognito:groups"];
  const isSuperAdmin = groups?.includes?.("superadmin");
  const isAdmin = groups?.includes?.("admin");

  // Initialize the list with the default order
  const list: any = [home];

  if (isAdmin) {
    list.push(
      {
        name: "dashboard",
        list: DashboardPage,
        options: { label: "Dashboard" },
        icon: <Icons.DashboardOutlined />,
      },
      patients,
      {
        name: "insights",
        list: Insights,
        options: { label: "Insights" },
        icon: <Icons.EyeOutlined />,
      },
      {
        name: "survey",
        list: SurveyFlowPage,
        options: { label: "Survey Flow" },
        icon: <Icons.NodeCollapseOutlined />,
      }
    );
  }

  if (isSuperAdmin) {
    list.push(
      {
        name: "users",
        list: UserManagement,
        icon: <Icons.TeamOutlined />,
        options: {
          label: "User Management",
        },
      },
      {
        name: "definitions",
        list: Definitions,
        icon: <Icons.FileTextOutlined />,
      }
    );
  }

  return { list, isAdmin, groups };
};
