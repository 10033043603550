import {
  IResourceComponentsProps,
  useNavigation,
  useList,
  useCreate,
  useUpdate,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  DateField,
  Space,
  EditButton,
  ShowButton,
  Button,
  Icons,
  Drawer,
  Tooltip,
  Form,
  Input,
  message,
  Popconfirm,
} from "@pankod/refine-antd";

import { Segmented, Spin } from "antd";

import dayjs from "dayjs";

import { useCallback, useContext, useEffect, useState } from "react";
import GroupMessage from "../forms/groupMessage";
import CardItem from "components/cardItem";

import debounce from "lodash.debounce";
import { UserDataContext } from "App";
import { joinPatientsWithTreatments } from "utils/tableJoin";
import { matchValue } from "utils/getPatientsData";
import { getSingleColorCode } from "utils/getGradeColorCode";
import { toFullName } from "utils/usernameTransform";

const { PlusOutlined, ArrowLeftOutlined, SearchOutlined } = Icons;

export const PatientList: React.FC<IResourceComponentsProps> = () => {
  const [form] = Form.useForm();
  const user = useContext(UserDataContext);
  const { create } = useNavigation();
  const { mutate, isLoading } = useCreate();
  const { mutate: update } = useUpdate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [messageDrawerVisible, setMessageDrawerVisible] =
    useState<boolean>(false);

  const [filteredRecords, setFilteredRecords] = useState(0);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onMessageDrawerClose = () => {
    form.resetFields();
    setSelectedRowKeys([]);
    setMessageDrawerVisible(false);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const { tableProps } = useTable<any>({
    syncWithLocation: false,
  });

  const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleUpdate = async (record: any, highlighted: boolean) => {
    setLoadingRows((prev) => ({ ...prev, [record.id]: true }));
    update(
      {
        resource: "patients",
        id: record.id,
        values: { ...record, highlighted },
      },
      {
        onSuccess: () => {
          setLoadingRows((prev) => ({ ...prev, [record.id]: false }));
        },
        onError: () => {
          setLoadingRows((prev) => ({ ...prev, [record.id]: false }));
        },
      }
    );
  };

  //for group message
  const selectedPatients = tableProps?.dataSource
    ?.filter((item) => item.status !== "inactive")
    ?.filter((item) => selectedRowKeys.includes(item.id))
    ?.map((item) => {
      return {
        fullName: item?.fullName,
        phoneNumber: `${item?.phoneNumber?.dialCode}${item?.phoneNumber?.number}`,
      };
    });

  const patiensList = tableProps?.dataSource;

  const treatmentsList = useList({
    resource: "treatments",
    queryOptions: {
      refetchOnWindowFocus: true,
    },
  });

  const surveysList = useList({
    resource: "surveys",
    queryOptions: {
      refetchOnWindowFocus: true,
    },
  });

  const [patientsData, setPatientsData] = useState<any>();

  useEffect(() => {
    let abortController = new AbortController();

    const tl: any = treatmentsList?.data?.data;
    const pl: any = tableProps?.dataSource;
    // ?.filter(
    //   (e) => e.status === "active" || e.status === undefined
    // );

    const sl: any = surveysList?.data?.data;

    let jointedData = joinPatientsWithTreatments(pl, tl, sl);

    setPatientsData(jointedData);

    return () => {
      abortController.abort();
    };
  }, [
    treatmentsList?.data?.data,
    surveysList?.data?.data,
    tableProps?.dataSource,
  ]);

  const [_, setFilterValue] = useState("");

  const filterPatients = useCallback(
    (e) => {
      const currValue = e.target.value;
      setFilteredRecords(0);
      setFilterValue(currValue);
      if (currValue !== "") {
        const filteredVals = tableProps?.dataSource?.filter?.(
          (entry: any) =>
            entry.fullName.toLowerCase().includes(currValue.toLowerCase()) ||
            entry.patientId.toLowerCase().includes(currValue.toLowerCase())
        );

        setPatientsData(filteredVals);
      } else {
        const tl: any = treatmentsList?.data?.data;
        const pl: any = tableProps?.dataSource?.filter(
          (e) => e.status === "active" || e.status === undefined
        );
        const sl: any = surveysList?.data?.data;

        let jointedData = joinPatientsWithTreatments(pl, tl, sl);

        setPatientsData(jointedData);
      }
    },
    [patiensList, patientsData]
  );

  const unique = (value: any, index: number, self: any) => {
    return self.indexOf(value) === index;
  };

  const filterBy = useCallback(
    (key: string, hasValue: boolean = false) => {
      const a = patientsData
        ?.map((item: any) => item[key])
        ?.filter(unique)
        ?.map((item: any) => {
          return {
            text: hasValue ? matchValue(item, key) : toFullName(item),
            value: item,
          };
        });

      return a;
    },
    [patientsData]
  );

  const [filterType, setFilterType] = useState<string>("all");

  const handleSegmentedChange: any = (value: string) => {
    setFilterType(value);

    if (value === "all") {
      setPatientsData(
        tableProps?.dataSource?.filter(
          (patient: any) => patient.status !== "inactive"
        )
      );
    } else if (value === "marked") {
      setPatientsData(
        tableProps?.dataSource?.filter(
          (patient: any) => patient.highlighted && patient.status !== "inactive"
        )
      );
    } else if (value === "urgent") {
      setPatientsData(
        tableProps?.dataSource?.filter(
          (patient: any) => patient.emergency && patient.status !== "inactive"
        )
      );
    }
  };

  return (
    <List
      headerProps={{
        title: (
          <Space size={32}>
            <span>
              <span style={{ fontSize: 24 }}>{`Patients `}</span>
              <span style={{ fontSize: 16, color: "#afafaf " }}>
                (
                {filteredRecords && filteredRecords !== patientsData?.length
                  ? `${filteredRecords}/`
                  : ""}
                {patientsData
                  ? `${
                      patientsData?.filter(
                        (e: any) =>
                          e.status === "active" || e.status === undefined
                      ).length
                    }`
                  : ""}
                )
              </span>
            </span>
            <Input
              onChange={debounce((e) => filterPatients(e), 500)}
              style={{
                width: "360px",
              }}
              placeholder="Search patients"
              prefix={<SearchOutlined />}
              allowClear
            />
            <Segmented
              onChange={handleSegmentedChange}
              options={[
                { label: "All", value: "all", icon: <Icons.BarsOutlined /> },
                {
                  label: "Marked",
                  value: "marked",
                  icon: <Icons.StarOutlined />,
                },
                {
                  label: "Urgent",
                  value: "urgent",
                  icon: <Icons.AlertOutlined />,
                },
              ]}
            />
          </Space>
        ),

        extra: (
          <Space>
            <Button
              disabled={!hasSelected}
              icon={<Icons.MobileOutlined />}
              onClick={(): void => {
                if (!hasSelected) return;

                setMessageDrawerVisible(true);
                form.setFieldsValue({ recepients: selectedRowKeys });
              }}
            >
              Send Group Text
              {selectedRowKeys.length ? ` (${selectedRowKeys.length})` : null}
            </Button>

            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={(): void => create("patients")}
            >
              New Patient
            </Button>
          </Space>
        ),
      }}
    >
      <Table
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredRecords(extra?.currentDataSource?.length);
        }}
        size="middle"
        dataSource={
          patientsData &&
          patientsData?.filter((patient: any) => patient.status !== "inactive")
        }
        loading={tableProps?.loading}
        rowKey="id"
        rowSelection={rowSelection}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 30,
          onChange: () => window.scrollTo(0, 0),
          size: "default",
        }}
        rowClassName={(record) => (record?.emergency ? "urgent-row" : "")}
      >
        <Table.Column
          dataIndex="highlighted"
          render={(_, record: any) => (
            <div style={{ marginLeft: "20px" }}>
              {loadingRows[record.id] ? (
                <Spin size="small" />
              ) : record?.highlighted ? (
                <Icons.StarFilled
                  className="star-icon star-active"
                  onClick={() => handleUpdate(record, false)}
                />
              ) : (
                <Icons.StarOutlined
                  className="star-icon star-inactive"
                  onClick={() => handleUpdate(record, true)}
                />
              )}
            </div>
          )}
        />

        <Table.Column
          dataIndex="patientId"
          key="patientId"
          title="Patient ID"
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title="Full Name"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="DD/MM/YYYY" />}
          defaultSortOrder="descend"
          // sorter={(a: any, b: any) =>
          //   dayjs(a?.createdAt).unix() - dayjs(b?.createdAt).unix()
          // }
        />

        {/* <Table.Column
          width={120}
          dataIndex="language"
          key="language"
          title="Language"
          render={(value, record) => (
            <CardItem datakey="language" valkey={value} />
          )}
          onFilter={(value, record: any) =>
            String(record.language).indexOf?.(String(value)) === 0
          }
          filters={filterBy("language", true)}
        /> */}

        <Table.Column
          dataIndex="createdBy"
          key="createdBy"
          title="Doctor"
          render={(value) =>
            typeof value === "string" ? (
              <TextField value={toFullName(value)} />
            ) : (
              <></>
            )
          }
          onFilter={(value, record: any) => {
            return record.createdBy?.indexOf?.(value) === 0;
          }}
          filters={filterBy("createdBy")}
        />

        <Table.Column
          dataIndex="treatmentStatus"
          key="treatment"
          title="Treatment status"
          render={(value, record: any) =>
            record?.treatmentStatus === "Not Configured" ? (
              <ShowButton
                style={{ padding: 0 }}
                recordItemId={record?.id}
                type="link"
                danger
                icon={null}
              >
                {value}
              </ShowButton>
            ) : (
              <span>{value}</span>
            )
          }
          onFilter={(value, record: any) =>
            record.treatmentStatus?.indexOf?.(value) === 0
          }
          filters={filterBy("treatmentStatus")}
        />
        <Table.Column
          dataIndex="histology"
          key="histology"
          title="Diagnosis"
          render={(value, record: any) => (
            <>
              {record?.biologicalSubtype || record?.biologicalSubtype === 0 ? (
                <Tooltip
                  placement="bottom"
                  color="#007787"
                  title={
                    <CardItem
                      datakey="biologicalSubtype"
                      valkey={record?.biologicalSubtype}
                    />
                  }
                >
                  <CardItem datakey="histology" valkey={value} /> {""}
                </Tooltip>
              ) : (
                <CardItem datakey="histology" valkey={value} />
              )}
            </>
          )}
          onFilter={(value, record: any) =>
            String(record.histology).indexOf?.(String(value)) === 0
          }
          filters={filterBy("histology", true)}
        />
        <Table.Column
          width="80px"
          dataIndex="cancerStage"
          key="cancerStage"
          title="Stage"
          render={(value) => (
            <CardItem
              spacewidth="11px"
              datakey="cancerStage"
              valkey={value}
              spanwidth="80px"
            />
          )}
          onFilter={(value, record: any) =>
            String(record.cancerStage).indexOf?.(String(value)) === 0
          }
          filters={filterBy("cancerStage", true)}
        />

        {surveysList?.data?.data?.[0] && (
          <Table.Column
            // width={220}
            dataIndex="severity"
            key="severity"
            title="Severity"
            render={(_, record: any) => {
              if (!record.survey) return <></>;

              const grades = record?.survey?.map(
                (rec: any) => Number(rec.grade) || 0
              );

              const max = Math.max(...grades);
              const index = grades.indexOf(Math.max(...grades));
              const id = record?.survey?.[index]?.id;
              const gradeTitle = record?.survey?.[index]?.gradeTitle;
              const isFinished = record?.survey?.[index]?.isFinished;

              return (
                <Tooltip
                  placement="bottom"
                  color="#007787"
                  title={record?.survey?.[index]?.title}
                >
                  <Space>
                    {getSingleColorCode(id, max, !isFinished)}
                    <span>{gradeTitle}</span>
                  </Space>
                </Tooltip>
              );
            }}
            onFilter={(value: any, record: any) => {
              const s = record?.survey?.map?.((item: any) => item.isFinished);

              return s?.includes?.(value);
            }}
            filters={[
              {
                text: "Finished",
                value: true,
              },
              {
                text: "Ongoing",
                value: false,
              },
            ]}
          />
        )}

        {/* <Table.Column
          width="40px"
          dataIndex="emergency"
          key="emerhency"
          title=""
          render={(value) => 
            {value?.emergency ? (
              <Icons.ExclamationCircleFilled className="star-icon star-active" />
            ) : (
              <Icons.ExclamationCircleOutlined className="star-icon star-inactive" />
            )}
          }

        /> */}

        <Table.Column
          // width={64}
          fixed="right"
          dataIndex="actions"
          render={(_, record: any) => (
            <div style={{ marginLeft: "20px" }}>
              <Space>
                <EditButton hideText recordItemId={record?.id} />
                <ShowButton hideText recordItemId={record?.id} />
                <Popconfirm
                  title="Are you sure to delete this patient ?"
                  onConfirm={() =>
                    update({
                      resource: "patients",
                      id: record?.id,
                      values: {
                        ...tableProps?.dataSource?.find(
                          (item) => item.id === record?.id
                        ),
                        status: "inactive",
                      },
                    })
                  }
                  okText="Delete"
                  cancelText="Cancel"
                  placement="left"
                >
                  {" "}
                  <Button icon={<Icons.DeleteOutlined />} />
                </Popconfirm>
              </Space>
            </div>
          )}
        />
      </Table>

      <Drawer
        closeIcon={<ArrowLeftOutlined />}
        width="50vw"
        title="New Message"
        visible={messageDrawerVisible}
        onClose={() => setMessageDrawerVisible(false)}
        footer={
          <Space style={{ margin: 16 }} size={16}>
            {" "}
            <Button
              type="primary"
              style={{ width: "180px" }}
              loading={isLoading}
              onClick={() => {
                form?.validateFields().then((val) => {
                  mutate(
                    {
                      resource: "messages",
                      values: {
                        ...val,
                        selectedPatients,
                        createdBy: user?.username,
                      },
                    },
                    {
                      onSuccess: () => {
                        onMessageDrawerClose();
                        message.success("The message was sent successfully");
                      },
                    }
                  );
                });
              }}
            >
              Send Message
            </Button>
            <Button style={{ width: "180px" }} onClick={onMessageDrawerClose}>
              Cancel
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical">
          <GroupMessage onSelect={setSelectedRowKeys} form={form} />
        </Form>
      </Drawer>
    </List>
  );
};
