import { Button, Icons, List } from '@pankod/refine-antd';
import React from 'react';

const {FileSyncOutlined} = Icons

const Insights = () => {
  return (
    <List
    headerProps={{
        title: "Insights",
        extra: (<Button icon={<FileSyncOutlined />}>
            Syncronize data
        </Button>)
    }}
    >
      <iframe
        title="HuroneAI Bot"
        src="https://app.vectorshift.ai/chatbots/deployed/66fea4c34a8d992e88799848"
        width="98%"
        height="90vh" // Use string value for CSS units like vh or %
        style={{
          height: '80vh',  // Apply 90vh as a string in the style attribute
          border: 'none',
          position: 'fixed',
          bottom: 0,
          right: 25,
        //   margin: '-10px',
        }}
        allow="clipboard-read; clipboard-write; microphone"
      />
    </List>
  );
};

export default Insights;
