import React, { FC } from "react";
import { Column } from "@ant-design/plots";

import survey from "../../assets/data/survey.json"

const VerticalBarChart = ({ data }:any) => {
  // Transform the data to group by each `questionList` item
  const chartData: any = [];

  if (!data || data.length === 0) {
    return <div>No data available</div>; // Uvek vraća validan JSX element
  }

  data.forEach((entry: any) => {
    if (entry.questionList && Array.isArray(entry.answers)) {
      entry.questionList.forEach((questionId: any, index: any) => {
        const matchingAnswer = entry.answers[index];
        const question = survey.find((q) => q.id === questionId);
        if (matchingAnswer && question) {
          chartData.push({
            question: questionId, // Each question ID is its own group
            title: question.title.replace("In the last 7 days", "").trim().toUpperCase(), 
            date: new Date(entry.createdAt).toLocaleDateString(),
            grade: parseInt(matchingAnswer.answer, 10), // Grade for y-axis
            // color: "orange"
          });
        }
      });
    }
  });

  const config = {
    data: chartData,
    xField: "question", // Top x-axis groups by question ID
    yField: "grade", // Y-axis shows grades
    seriesField: "date", // Bars within each group represent dates
    isGroup: true, // Ensure grouping by `question`
    barWidthRatio: 0.6,
    // color: ["#61DDAA", "#F6BD16", "#F5A623", "#FF6B6B", "#FF0000"],

    xAxis: {
      title: {
        text: "Question ID (Group)",
      },
    },
    yAxis: {
      title: {
        text: "Grade",
      },
    },
    tooltip: {
      shared: true,
      showMarkers: true,
    },
    // legend: {
    //   position: "top",
    // },
  };

  console.log({chartData})

  return <Column {...config} />;
};

export default VerticalBarChart;
