import React, { useState } from "react";
import {
  CalendarOutlined,
  FilterOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { useList } from "@pankod/refine-core";
import {
  Badge,
  Card,
  List,
  Skeleton as AntdSkeleton,
  Typography,
  Tooltip,
  Button,
  Dropdown,
  Menu,
  Space,
} from "antd";
import dayjs from "dayjs";
import { Text } from "components/text";

const priorityColors: any = {
  Routine: "green",
  Priority: "yellow",
  Urgent: "orange",
  Emergency: "red",
};

export const CalendarUpcomingEvents = () => {
  const { data, isLoading, isSuccess } = useList({
    resource: "appointments",
    config: {
      filters: [
        {
          field: "doctor",
          operator: "eq",
          value: "uros.pajic",
        },
      ],
    },
  });

  const [selectedPriority, setSelectedPriority] = useState(null);

  const events = (data?.data || []).filter((event) =>
    selectedPriority ? event.priority === selectedPriority : true
  );

  const filterMenu = (
    <Menu
      onClick={({ key }: any) => {
        setSelectedPriority(key === "clear" ? null : key);
      }}
    >
      {Object.keys(priorityColors).map((priority) => (
        <Menu.Item key={priority}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Badge color={priorityColors[priority]} /> {priority}
          </div>
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item key="clear">
        <div>Clear Filter</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      style={{
        height: "100%",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
      headStyle={{ padding: "8px 16px", background: "#f5f5f5" }}
      bodyStyle={{
        padding: "0 1rem",
      }}
      title={
        <Space
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <CalendarOutlined />
            <Text size="sm" style={{ marginLeft: ".7rem", fontWeight: "bold" }}>
              Upcoming Events
            </Text>
          </div>
          <Dropdown overlay={filterMenu} trigger={["click"]}>
            <Button
              icon={<FilterOutlined />}
              type={selectedPriority ? "primary" : "default"}
            />
          </Dropdown>
        </Space>
      }
    >
      {isLoading ? (
        <List
          itemLayout="horizontal"
          dataSource={Array.from({ length: 5 }, (_, index) => ({ key: index }))}
          renderItem={(_, index) => (
            <List.Item key={index}>
              <List.Item.Meta
                avatar={<Badge color="transparent" />}
                title={
                  <AntdSkeleton.Button
                    active
                    style={{
                      height: "14px",
                      width: "100px",
                    }}
                  />
                }
                description={
                  <AntdSkeleton.Button
                    active
                    style={{
                      width: "300px",
                      marginTop: "8px",
                      height: "16px",
                    }}
                  />
                }
              />
            </List.Item>
          )}
        />
      ) : isSuccess && events && events.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={events}
          renderItem={(item) => {
            const renderDate = () =>
              dayjs(item.appointmentDate).format("MMM DD, YYYY - HH:mm");

            return (
              <List.Item key={item?.id}>
                <List.Item.Meta
                  avatar={
                    <Badge
                      color={priorityColors[item?.priority]}
                      size="default"
                    />
                  }
                  title={<Text size="xs">{renderDate()}</Text>}
                  description={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text ellipsis={{ tooltip: true }} strong>
                        {item?.appointmentReason}
                      </Text>
                      {item?.notes && (
                        <Tooltip title={item?.notes} placement="topRight">
                          <SnippetsOutlined
                            style={{ cursor: "pointer", fontSize: "1.2rem" }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
      ) : (
        <NoEvent />
      )}
    </Card>
  );
};

const NoEvent = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "220px",
      color: "#888",
      fontStyle: "italic",
    }}
  >
    No Upcoming Events
  </div>
);
