import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Spin,
  useSelect,
} from "@pankod/refine-antd";

import { Segmented } from "antd";
import { FC, useRef, useState } from "react";
import handleCarretPosition from "utils/handleCarretPosition";
import { tagRender, maxTagPlaceholder } from "utils/tagRender/tagRender";

interface IGroupMessageProps {
  recepients?: any;
  onSend?: (values: any, cb: any) => void;
  onCancel?: any;
  onSelect?: any;
  setFormInstance?: any;
  hiddenRecepients?: boolean;
  form?: any;
  initialMessage?: string;
  generateAI?: boolean;
  isGenerating?: boolean;
}

const GroupMessage: FC<IGroupMessageProps> = (props) => {
  const {
    onSelect,
    form,
    recepients,
    hiddenRecepients,
    initialMessage,
    generateAI,
    isGenerating,
  } = props;

  const [patientsLoading, setPatientsLoading] = useState(true);
  const [language, setLanguage] = useState<string | number>("Kinyarwanda");
  const { selectProps: userSelectProps } = useSelect({
    resource: "patients",
    optionLabel: "fullName",
    optionValue: "id",
    filters: [
      {
        field: "status",
        operator: "eq",
        value: "active",
      },
    ],
    queryOptions: {
      enabled: true,
      onSuccess: () => {
        setPatientsLoading(false);
      },
    },
  });

  const inputRef: any = useRef();
  const { getCaretPosition, setCaretPosition } = handleCarretPosition(inputRef);

  const onInsertTemplate = (template: string) => {
    const currentPosition = getCaretPosition();
    const previousMessage = form.getFieldValue("body") || "";
    const updatedMessage = `${previousMessage.slice(
      0,
      currentPosition
    )} {{${template}}} ${previousMessage.slice(currentPosition)}`;

    form.setFieldsValue({ body: updatedMessage });
    setTimeout(
      () => setCaretPosition(currentPosition + template.length + 6),
      0
    );
  };

  return (
    <>
      {generateAI && (
        <div style={{ float: "right", margin: "flex" }}>
          <Button type="primary">Generate AI Response</Button>
        </div>
      )}
      <Form.Item
        name="recepients"
        label="Recepients"
        hidden={hiddenRecepients}
        rules={[{ required: true, message: "Recepients field is required." }]}
        initialValue={recepients}
      >
        {patientsLoading ? (
          <Spin />
        ) : (
          <Select
            mode="multiple"
            {...userSelectProps}
            loading={patientsLoading}
            onChange={(keys) => onSelect(keys)}
            showArrow
            tagRender={tagRender}
            maxTagCount={9}
            maxTagPlaceholder={maxTagPlaceholder}
            maxTagTextLength={9}
          />
        )}
      </Form.Item>
      {/* <Segmented
        block
        options={["Kinyarwanda", "English"]}
        value={language}
        onChange={setLanguage}
      /> */}

      <Form.Item
        name="body"
        label="Your Message"
        rules={[{ required: true, message: "Message body field is required." }]}
        initialValue={initialMessage}
      >
        <Input.TextArea
          ref={inputRef}
          // maxLength={160}
          rows={9}
          showCount={isGenerating}
          style={{ height: 120 }}
          autoSize={{ minRows: 4, maxRows: 10 }}
        />
      </Form.Item>

      <Space>
        <h4>Insert:</h4>
        <Button
          size="small"
          onClick={() => onInsertTemplate("fullName")}
          type="primary"
        >
          Full Name
        </Button>
      </Space>
    </>
  );
};

export default GroupMessage;
