const categoryColors: any = {
    cancerStage: "purple",
    histology: "teal",
    biologicalSubtype: "darkblue",
    surgery: "darkgreen",
    radiotherapy: "brown",
    chemotherapy: "darkred",
    chemotherapyAC: "gold",
    hormonalTherapy: "pink",
    targetedTherapy: "cyan",
    bisphosphonate: "magenta",
  };

  const symptomData = [
    { phrase: "dry mouth", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "mouth or throat sores", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "nausea", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "vomiting", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "shortness of breath", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "arm or leg swelling", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "pounding or racing heartbeat", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "dry skin", severity: ["mild", "moderate", "severe", "very severe"] },
    { phrase: "numbness or tingling in hands or feet", severity: ["mild", "moderate", "severe", "very severe"] },
  ];
  
  const gradesColors:any = {
    "very severe": "red",
    severe: "red",
    moderate: "orange",
    mild: "green",
    "almost constantly": "red",
    "almost constant": "red",
    frequently: "red",
    frequent: "red",
    occasionally: "orange",
    occasional: "orange", 
    rarely: "green",
    "not at all": "green",
    never: "green",
    "very much": "red",
    "quite a bit": "red",
    "somewhat": "orange",
    "a little bit": "green",
    none: "gray",
  };
  

  const categoryData = {
    cancerStage: [
      { value: 0, label: "Stage 0" },
      { value: 1, label: "Stage 1" },
      { value: 2, label: "Stage 2" },
      { value: 3, label: "Stage 3" },
      { value: 4, label: "Stage 4" },
    ],
    histology: [
      { value: 0, label: "Infiltrative ductal carcinoma" },
      { value: 1, label: "Lobular carcinoma" },
      { value: 2, label: "Mucinous" },
      { value: 3, label: "Rhabdomyosarcoma" },
      { value: 4, label: "Inflammatory breast cancer" },
      { value: 5, label: "Other" },
    ],
    biologicalSubtype: [
      { value: 0, label: "ER and PR positive (HER-2 negative)" },
      { value: 1, label: "Triple positive (ER, PR and HER-2 positive)" },
      { value: 2, label: "Triple negative breast cancer" },
      { value: 3, label: "HER-2 positive and ER and PR negative" },
      
    ],
    surgery: [
      { value: 0, label: "Breast conservative surgery" },
      { value: 1, label: "Modified radical mastectomy" },
    ],
    radiotherapy: [
      { value: 0, label: "Whole breast radiotherapy - Conventional or hypofractionation" },
      { value: 1, label: "Post-mastectomy radiotherapy - Conventional or hypofractionation" },
      { value: 2, label: "Palliative radiotherapy - 5 or 10 fractions" },
    ],
    chemotherapy: [
      { value: 0, label: "Paclitaxel" },
      { value: 1, label: "Cabecitabine" },
      { value: 2, label: "Carboplatin Paclitaxel" },
      { value: 3, label: "Docitaxel" },
      { value: 4, label: "Carboplatin" },
  
    ],
    chemotherapyAC: [
      { value: 0, label: "AC-T (Adramycin, Cyclophosphamide, Paclitaxel)" },
      { value: 1, label: "AC-TH (Adramycin, Cyclophophamide, Paclitaxel, Trastuzumab)" },
    ],
    hormonalTherapy: [
      { value: 0, label: "Tamoxifen" },
      { value: 1, label: "Anastrozole" },
      { value: 2, label: "Letrozole" },
    ],
    targetedTherapy: [{ value: 0, label: "Trastuzumab" }],
    bisphosphonate: [{ value: 0, label: "Zometa" }],
  };
  
  
  
  const highlightText = (text: string): React.ReactNode => {
    let highlightedText = text;
  
    // Highlight severity levels (case-insensitive)
    Object.keys(gradesColors)
      .sort((a, b) => b.length - a.length) // Match longer phrases first
      .forEach((grade) => {
        const regex = new RegExp(`\\b(${grade})\\b`, "gi");
        highlightedText = highlightedText.replace(
          regex,
          `<span style="color: ${gradesColors[grade.toLowerCase()]}; font-weight: bold;">$1</span>`
        );
      });
  
    // Highlight symptom phrases (case-insensitive and bold)
    symptomData.forEach(({ phrase }) => {
      const regex = new RegExp(`\\b(${phrase})\\b`, "gi");
      highlightedText = highlightedText.replace(
        regex,
        `<span style="font-weight: bold;">$1</span>` // Bold phrases
      );
    });
  
    // Highlight categories (e.g., Stage III, Infiltrative ductal carcinoma)
    Object.entries(categoryData).forEach(([category, items]) => {
      items.forEach(({ label }) => {
        const regex = new RegExp(`\\b(${label})\\b`, "gi");
        highlightedText = highlightedText.replace(
          regex,
          `<span style="color: ${categoryColors[category]}; font-style: italic; font-weight: bold;">$1</span>`
        );
      });
    });
  
    return <div dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };
  
  
  
  


  export const HighlightedSummary: React.FC<{ summary: string }> = ({ summary }) => {
    return <div style={{ lineHeight: "1.8" }}>{highlightText(summary)}</div>;
  };
  
  
  
