import axios from "axios";

// const openai = axios.create({
//   baseURL: "https://api.openai.com/v1",
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer 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`,
//   },
// });

// export const getResponseAI = (message: string) => {
//   const messages = [{ role: "user", content: message }];

//   const options: any = {
//     temperature: 0.8,
//     max_tokens: 500,
//   };

//   return openai.post("/chat/completions", {
//     model: "gpt-3.5-turbo",
//     messages,
//     ...options,
//   });
// };

export const getResponseAI = (message: string, reason: string) => {
  const content = {
    body: JSON.stringify({
      prompt: message,
      reason: reason, // Add the reason parameter to the payload
    }),
  };
  return axios.post(
    "https://o67x6ip6wk.execute-api.us-west-2.amazonaws.com/dev/generate",
    content
  );
};
