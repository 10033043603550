import React, { useState } from 'react';

import { LayoutProps } from '@pankod/refine-core';
import { AntdLayout, Grid } from '@pankod/refine-antd';
import ChatInterface from 'components/chat';

export const Layout: React.FC<any> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
  setChatVisible,
  isChatVisible
}) => {
  const breakpoint = Grid.useBreakpoint();
  // const [isChatVisible, setChatVisible] = useState(true);
  
  return (
    <AntdLayout
      style={{
        minHeight: '100vh',
      }}
    >
      {Sider && <Sider />}
      <AntdLayout>
        {Header &&  <Header />}
        <AntdLayout.Content>
          <div
            style={{
              marginTop: '48px',
              padding: breakpoint.sm ? 24 : 12,
              minHeight: 360,
              display: 'flex',
              flexDirection: 'column',
              // overflow: 'hidden',
              backgroundColor: '#fbfbfb',
            }}
          >
            {children}
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                // backgroundColor: "#f0f2f5",
              }}
            >
              <ChatInterface
                isVisible={isChatVisible}
                onClose={() => setChatVisible(false)}
              />
            </div>
          </div>
          {/* {OffLayoutArea && <OffLayoutArea />} */}
        </AntdLayout.Content>
        {/* {Footer && <Footer />} */}
      </AntdLayout>
    </AntdLayout>
  );
};
