import {
  useTable,
  List,
  Table,
  Button,
  Icons,
  Space,
  DateField,
  Tooltip,
  Form,
  Drawer,
  Select,
  Input,
  Collapse,
} from "@pankod/refine-antd";

import { getSingleColorCode } from "utils/getGradeColorCode";
import adaptSurveyData, { getSeveritySimptoms } from "utils/adaptSurveyData";
import dayjs from "dayjs";
import "./style.less";
import { useCreate, useList, useOne } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import GroupMessage from "pages/patients/forms/groupMessage";
import { matchValue } from "utils/getPatientsData";
import calculateAge from "utils/getAge";
import { getResponseAI } from "utils/getResponseAI";
import ConnectCCP from "../../../../components/callCenter";
import AnswerTrendChart from "components/surveyChart";
import AnswerTrendVerticalBarChart from "components/surveyChart";
import GroupedBarChart from "components/surveyChart";
import VerticalBarChart from "components/surveyChart";

const {
  DownOutlined,
  UpOutlined,
  MessageOutlined,
  ArrowLeftOutlined,
  PhoneOutlined,
  UndoOutlined,
  SaveOutlined,
  SettingOutlined,
  BulbOutlined,
} = Icons;

export const SurveyTabPane = (props: any) => {
  const { patientID, title, patient, treatment } = props;

  const [language, setLanguage] = useState("English");
  const [callDrawerVisible, setCallDrawerVisible] = useState(false);

  const [prompt, setPrompt] = useState("");

  const [form] = Form.useForm();
  const { mutate } = useCreate();
  const [messageDrawerVisible, setMessageDrawerVisible] =
    useState<boolean>(false);

  const [responseLoading, setResponseLoading] = useState(false);

  const surveysList = useList({
    resource: "surveys",
    queryOptions: {
      refetchOnWindowFocus: true,
    },
  });

  const { tableProps } = useTable({
    resource: "surveys",
    queryOptions: {
      refetchOnWindowFocus: true,
    },
    initialFilter: [
      {
        field: "patientID",
        operator: "eq",
        value: patientID,
      },
    ],
  });

  const surveys_: any = tableProps?.dataSource;

  const currentSurveyRecord: any = surveys_?.sort(
    (a: any, b: any) => dayjs(b?.createdAt).unix() - dayjs(a?.createdAt).unix()
  )[0];

  const lastSurveyRecord: any = surveys_?.sort(
    (a: any, b: any) => dayjs(b?.createdAt).unix() - dayjs(a?.createdAt).unix()
  )[1];

  const getStatus = (record: any) => {
    const { answers, questionList } = record;

    // const lastdate = tableProps?.dataSource
    //   ?.map((item) => item.createdAt)
    //   .reduce(function (a, b) {
    //     return a > b ? a : b;
    //   });

    if (answers?.length === 0) {
      return {
        status: "Not started",
        dateFinished: null,
      };
    }

    if (questionList?.length - 1 <= answers?.length) {
      return {
        status: "Finished",
        dateFinished: answers?.[answers?.length - 1]?.createdAt,
      };
    } else {
      //  const d = new Date(lastdate).getDay()
      //   const dd = new Date(answers?.[answers?.length - 1]?.createdAt).getDay()
      //   console.log(d, dd, d === dd)
      return {
        status: "Ongoing",
        dateFinished: null,
      };
    }
  };

  const patientsQuery: any = useOne({
    resource: "patients",
    id: patientID,
  });

  const patients = [patientsQuery?.data?.data]?.map?.((patient: any) => {
    return {
      fullName: patient?.fullName,
      phoneNumber: `${patient?.phoneNumber?.dialCode}${patient?.phoneNumber?.number}`,
    };
  });

  function getTherapyType(input: any) {
    const therapies = [
      "targetedTherapy",
      "chemotherapy",
      "chemotherapyAC",
      "bisphosphonate",
      "hormonalTherapy",
      "radiotherapy",
      "surgery",
    ];
    const result = [];

    for (const therapy of therapies) {
      if (therapy && Object.keys(input[therapy]).length > 0) {
        if (
          input[therapy].type !== undefined &&
          input[therapy].status === "Ongoing"
        ) {
          if (input[therapy].cycle)
            result.push({
              value: input[therapy].type,
              key: therapy,
              cycle: `${input[therapy].cycle.current}`,
            });
          else {
            result.push({ value: input[therapy].type, key: therapy });
          }
        }
      }
    }

    return result;
  }

  const getTherapyOutput = () => {
    const therapies = getTherapyType(treatment);

    const therapyOutput = [];
    for (const therapy of therapies) {
      const { value, key, cycle } = therapy;
      const therapyName = matchValue(value, key);
      const sufix = cycle === "1" ? "st" : "th";
      if (cycle) therapyOutput.push(`${cycle}${sufix} cycle of ${therapyName}`);
      else {
        therapyOutput.push(`${key} - ${therapyName}`);
      }
    }

    return therapyOutput.join(" along with ");
  };

  const getSurveyAnswers = () => {
    if (!currentSurveyRecord) return;
    const { questions, answers } = currentSurveyRecord;
    if (questions && answers) {
      const adapted = adaptSurveyData(questions, answers);

      console.log(adapted,232321)

      const output = adapted
        .filter((item: any) => item.colorRange.orange || item.colorRange.red)
        .map((item: any) => {
          const simptoms = getSeveritySimptoms(item.title);

          return `${item.gradeTitle} ${simptoms}`;
        });

      return output.join(", ");
    } else return;
  };

  const getSurveyTemplate = () => {
    const { dateOfBirth, gender, cancerStage, biologicalSubtype, histology } =
      patient;

    const template =
      `A ${calculateAge(dateOfBirth)} year-old ${
        gender === 1 ? "Male" : "Female"
      } diagnosed with ${cancerStage} stage ${matchValue(
        histology,
        "histology"
      )} cancer - ${matchValue(
        biologicalSubtype,
        "biologicalSubtype"
      )} is on their ${getTherapyOutput()}. 
    They report: ${getSurveyAnswers()}. 
` + "";
    // "As a clinical oncologist  provide a text message of maximum 700 characters, addressing patient concerns and side effects without a hospital visit, except you detect you flag it as life-threatening";

    // "As a clinical oncologist in a low-middle income country, provide a text message of maximum 700 characters, addressing patient concerns and side effects without a hospital visit, except you detect you flag it as life-threatening. Replace this sentence “if manageable, we can discuss further care via telehealth” in response to “if you need further care you can use Gukiza to schedule your next appointment” and it has to start with Dear {{fullName}}. ";
    setPrompt(template);
    return template;
  };

  const surevysAnswers: any = getSurveyAnswers();

  useEffect(() => {
    console.log("template");
    getSurveyTemplate();
  }, [surevysAnswers]);

  const onMessageDrawerClose = () => {
    // getSurveyAnswers();
    // getSurveyTemplate();

    form.resetFields();

    setMessageDrawerVisible(false);
  };

  const columns = [
    {
      title: "Started At",
      dataIndex: "createdAt",
      key: null,
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        dayjs(a?.createdAt).unix() - dayjs(b?.createdAt).unix(),
      render: (val: any) => (
        <Tooltip
          placement="bottom"
          title={
            <DateField value={val} format="LLL" style={{ color: "#fff" }} />
          }
          color="#007787"
        >
          <DateField value={val} format="DD/MM/YYYY" />
        </Tooltip>
      ),
    },
    // { title: "Completed At", dataIndex: "createdAt", key: null },
    {
      title: "Reason for Survey	",
      dataIndex: "reason",
      key: null,
      render: (val: any) => (val === "" ? "Pilot test" : val),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: null,
      render: (val: any, record: any) => getStatus(record)?.status,
    },
    {
      title: "Finished At",
      dataIndex: null,
      key: null,
      render: (val: any, record: any) => (
        <Tooltip
          placement="bottom"
          title={
            getStatus(record)?.dateFinished ? (
              <DateField
                value={getStatus(record)?.dateFinished}
                format="LLL"
                style={{ color: "#fff" }}
              />
            ) : (
              "/"
            )
          }
          color="#007787"
        >
          {getStatus(record)?.dateFinished ? (
            <DateField
              value={getStatus(record)?.dateFinished}
              format="DD/MM/YYYY"
            />
          ) : (
            "/"
          )}
        </Tooltip>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  const generateResponse = () => {
    // let m =
    //   language === "Kinyarwanda"
    //     ? getSurveyTemplate() + "Also show me translation to Kinyarwanda"
    //     : getSurveyTemplate();

    // console.log(m);
    setResponseLoading(true);
    form.setFieldsValue({
      body: "Please wait while the response is generated ...",
    });
    getResponseAI(prompt, "message").then((r) => {
      setResponseLoading(false);
      // console.log(
      //   r.data.usage.total_tokens,
      //   (Number(r.data.usage.total_tokens) * 0.002) / 1000 + " $"
      // );
      const a = JSON.parse(r?.data?.body);
      console.log(a);
      console.log({ r });
      form.setFieldsValue({
        body: a?.completion, //a?.results?.[0]?.outputText,
      });
    });
  };

  console.log(surveysList, 11)
  console.log(surveys_, 22)

  return (
    <List
      breadcrumb={false}
      headerProps={{
        title: "Surveys",
        extra: null,
      }}
    >
      
      <VerticalBarChart data={surveys_} />
      <Table
        {...tableProps}
        className="survey-table"
        rowKey="id"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <>
            
              <Table
                dataSource={adaptSurveyData(record.questions, record.answers)}
                pagination={false}
                footer={
                  surevysAnswers.length > 0 &&
                  // (
                  record?.id === currentSurveyRecord?.id &&
                  //   ||
                  // record?.id === lastSurveyRecord?.id)
                  record?.answers?.length > 0
                    ? () => (
                        <div
                          style={{
                            float: "right",
                            // marginTop: "16px",
                            // marginBottom: "8px",
                          }}
                        >
                          <Space>
                            {" "}
                            <Button
                              type="primary"
                              icon={<MessageOutlined />}
                              onClick={() => {
                                setMessageDrawerVisible(true);
                              }}
                            >
                              Send Message to Patient
                            </Button>
                            <Button
                              type="primary"
                              style={{ width: 220 }}
                              icon={<PhoneOutlined />}
                              onClick={() => {
                                setCallDrawerVisible(true);
                              }}
                            >
                              Call Patient
                            </Button>
                          </Space>
                        </div>
                      )
                    : () => null
                }
              >
                <Table.Column
                  dataIndex="index"
                  title="No."
                  render={(val, object, index) => `${index + 1}.`}
                />

                <Table.Column
                  dataIndex="id"
                  title="Question"
                  render={(val, object: any) => (
                    <div className="survey-answer">
                      <p>
                        {`${object.title} `}
                        {object.grades.map?.(
                          (grade: any) => `${grade.value} - ${grade.title}; `
                        )}
                      </p>
                    </div>
                  )}
                />
                <Table.Column
                  defaultSortOrder={"descend"}
                  sorter={(a: any, b: any) =>
                    Number(a?.grade) - Number(b?.grade)
                  }
                  dataIndex="grade"
                  title="Answer"
                  render={(val, object: any) => {
                    return (
                      <Tooltip
                        placement="bottom"
                        color="#007787"
                        title={
                          <DateField
                            value={object.createdAt}
                            format="lll"
                            style={{ color: "#fff" }}
                          />
                        }
                      >
                        <div className="survey-answer">
                          <p>{val}</p>{" "}
                          {getSingleColorCode(object?.id, Number(val))}
                        </div>
                      </Tooltip>
                    );
                  }}
                />
              </Table>
              {/* <div
                style={{
                  float: "right",
                  marginTop: "16px",
                  marginBottom: "8px",
                  backgroundColor: "yellow"
                }}
              >
           <CallCenter/>
              </div> */}
            </>
          ),
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button type="link" onClick={(e) => onExpand(record, e)}>
                <Space>
                  <span>Hide Details</span>
                  <UpOutlined />
                </Space>
              </Button>
            ) : (
              <Button
                type="link"
                onClick={(e) => {
                  onExpand(record, e);
                  console.log(record);
                  //setSurveyId(record?.id)
                }}
              >
                <Space>
                  <span>View Details</span>
                  <DownOutlined />
                </Space>
              </Button>
            ),
        }}
        pagination={{
          ...tableProps.pagination,
          position: ["bottomCenter"],
        }}
      />

      <Drawer
        destroyOnClose
        closeIcon={<ArrowLeftOutlined />}
        width="50vw"
        title="New Message"
        visible={messageDrawerVisible}
        onClose={() => {
          setMessageDrawerVisible(false);
          form?.resetFields();
        }}
        footer={
          <Space style={{ margin: 16 }} size={16}>
            {" "}
            <Button
              type="primary"
              style={{ width: "180px" }}
              onClick={() => {
                form?.validateFields().then((val) => {
                  mutate(
                    {
                      resource: "messages",
                      values: {
                        ...val,
                        patients,
                      },
                    },
                    {
                      onSuccess: () => {
                        onMessageDrawerClose();
                      },
                    }
                  );
                  console.log(val);
                });
              }}
            >
              Send Message
            </Button>
            <Button style={{ width: "180px" }} onClick={onMessageDrawerClose}>
              Cancel
            </Button>
          </Space>
        }
      >
        <>
          {/* <Space>
            <Select
              options={[{ value: "Kinyarwanda" }, { value: "English" }]}
              value={language}
              onChange={setLanguage}
              style={{ width: "220px" }}
            />
            <Button
              type="primary"
              onClick={() => generateResponse()}
              loading={responseLoading}
            >
              Generate AI Response
            </Button>
          </Space> */}

          <Collapse
            // defaultActiveKey={["1"]}
            style={{ marginTop: "8px", marginBottom: "8px" }}
          >
            <Collapse.Panel
              header={
                <Space>
                  <span>AI Response</span>
                  <BulbOutlined />
                </Space>
              }
              key="1"
              style={{ padding: 0 }}
            >
              <div>
                <Space>
                  <Button
                    icon={<UndoOutlined />}
                    onClick={getSurveyTemplate}
                    style={{
                      marginTop: "4px",
                      marginBottom: "8px",
                      // marginRight: "8px",
                    }}
                  />
                  <Select
                    showSearch
                    placeholder="Select a template"
                    optionFilterProp="children"
                    style={{
                      marginTop: "4px",
                      marginBottom: "8px",
                      width: "430px",
                    }}
                  />
                  <Button
                    icon={<SaveOutlined />}
                    onClick={() => alert("Not implemented")}
                    style={{
                      marginTop: "4px",
                      marginBottom: "8px",
                      // marginRight: "8px",
                    }}
                  />

                  <Button
                    icon={<SettingOutlined />}
                    onClick={() => alert("Not implemented")}
                    style={{
                      marginTop: "4px",
                      marginBottom: "8px",
                      // marginRight: "8px",
                    }}
                  />
                </Space>
                <Input.TextArea
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  rows={10}
                />
                <Button
                  type="primary"
                  onClick={() => generateResponse()}
                  loading={responseLoading}
                  style={{
                    marginTop: "8px",
                  }}
                >
                  Generate Response
                </Button>
              </div>
            </Collapse.Panel>
          </Collapse>
          <Form form={form} layout="vertical">
            <GroupMessage
              hiddenRecepients
              recepients={[patientID]}
              form={form}
              isGenerating={!responseLoading}
              // initialMessage={}
            />
          </Form>
        </>
      </Drawer>
      <Drawer
        closeIcon={<ArrowLeftOutlined />}
        width="40vw"
        title="Call patient"
        visible={callDrawerVisible}
        onClose={() => setCallDrawerVisible(false)}
      >
        <ConnectCCP />
      </Drawer>
    </List>
  );
};
