/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "855c5dd1245840da802afc141c548cf8",
    "aws_mobile_analytics_app_region": "eu-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "855c5dd1245840da802afc141c548cf8",
            "region": "eu-west-2"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://h1qaukaq3a.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "appointmentsAPI",
            "endpoint": "https://nhdmnpqrod.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "bedrockAPI",
            "endpoint": "https://mhg372mkq5.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "messagesAPI",
            "endpoint": "https://kspr3gn0ug.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "patientsAPI",
            "endpoint": "https://k9czz4xjil.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "supportAPI",
            "endpoint": "https://2vpy50nwb7.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "surveysAPI",
            "endpoint": "https://qinh1gp3wd.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        },
        {
            "name": "treatmentsAPI",
            "endpoint": "https://whocrgdiog.execute-api.eu-west-2.amazonaws.com/demo",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:f1e26754-d414-479b-a0b0-df95fad2e075",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_BNajbZ9c3",
    "aws_user_pools_web_client_id": "148i0r6tej7646sl709ek9l8gn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "appointmentsDB-demo",
            "region": "eu-west-2"
        },
        {
            "tableName": "messagesDB-demo",
            "region": "eu-west-2"
        },
        {
            "tableName": "patientsDB-demo",
            "region": "eu-west-2"
        },
        {
            "tableName": "supportDB-demo",
            "region": "eu-west-2"
        },
        {
            "tableName": "surveysDB-demo",
            "region": "eu-west-2"
        },
        {
            "tableName": "treatmentsDB-demo",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
