"use client";

import React, { memo, useCallback } from "react";
import ReactFlow, {
  Node,
  Edge,
  Handle,
  Position,
  ReactFlowProvider,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  ConnectionMode,
  Controls,
  MiniMap,
} from "reactflow";
import "reactflow/dist/style.css";

interface Grade {
  value: number;
  title: string;
}

interface SurveyQuestion {
  id: string;
  title: string;
  grades: Grade[];
  emergency?: string;
}

const surveyData: SurveyQuestion[] = [
  {
    id: "4b",
    title:
      "In the last 7 days, what was the SEVERITY of your VOMITING at its WORST?",
    grades: [
      { value: 1, title: "None" },
      { value: 2, title: "Mild" },
      { value: 3, title: "Moderate" },
      { value: 4, title: "Severe" },
      { value: 5, title: "Very severe" },
    ],
  },
  {
    id: "6a",
    title:
      "In the last 7 days, what was the SEVERITY of your SHORTNESS OF BREATH at its WORST?",
    emergency: "Are you experiencing chest pain or difficulty breathing",
    grades: [
      { value: 1, title: "None" },
      { value: 2, title: "Mild" },
      { value: 3, title: "Moderate" },
      { value: 4, title: "Severe" },
      { value: 5, title: "Very severe" },
    ],
  },
  {
    id: "8a",
    title: "In the last 7 days, how OFTEN did you have ARM OR LEG SWELLING?",
    emergency: "Is the swelling accompanied by redness, warmth, or pain?",
    grades: [
      { value: 1, title: "Never" },
      { value: 2, title: "Rarely" },
      { value: 3, title: "Occasionally" },
      { value: 4, title: "Frequently" },
      { value: 5, title: "Almost constantly" },
    ],
  },
  {
    id: "11a",
    title: "In the last 7 days, did you have any HAIR LOSS?",
    grades: [
      { value: 1, title: "Not at all" },
      { value: 2, title: "A little bit" },
      { value: 3, title: "Somewhat" },
      { value: 4, title: "Quite a bit" },
      { value: 5, title: "Very much" },
    ],
  },
];

const styles = `
  .node {
    border-radius: 8px;
    padding: 16px;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .start-node {
    background-color: #22c55e;
    color: white;
    width: 256px;
    text-align: center;
  }
  .end-node {
    background-color: #ef4444;
    color: white;
    width: 256px;
    text-align: center;
  }
  .question-node {
    background-color: white;
    width: 256px;
  }
  .emergency-node {
    background-color: #fbbf24;
    width: 256px;
  }
  .report-emergency-node {
    background-color: #dc2626;
    color: white;
    width: 256px;
    text-align: center;
  }
  .node-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .grade-item, .answer-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    background-color: #f3f4f6;
    padding: 8px;
    border-radius: 4px;
    position: relative;
  }
  .any-answer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    background-color: #e5e7eb;
    padding: 8px;
    border-radius: 4px;
    position: relative;
  }
  .handle {
    width: 12px;
    height: 12px;
  }
`;

const StartNode: React.FC = memo(() => (
  <div className="node start-node">
    <div className="node-title">Start Survey</div>
    <Handle
      type="source"
      position={Position.Bottom}
      className="handle"
      style={{ backgroundColor: "#22c55e" }}
    />
  </div>
));

const EndNode: React.FC = memo(() => (
  <div className="node end-node">
    <Handle
      type="target"
      position={Position.Top}
      className="handle"
      style={{ backgroundColor: "#ef4444" }}
    />
    <div className="node-title">End Survey</div>
  </div>
));

interface QuestionNodeProps {
  data: SurveyQuestion;
}

const QuestionNode: React.FC<QuestionNodeProps> = memo(({ data }) => (
  <div className="node question-node">
    <Handle
      type="target"
      position={Position.Top}
      className="handle"
      style={{ backgroundColor: "#3b82f6" }}
    />
    <div className="node-title">{data.title}</div>
    {data.grades.map((grade) => (
      <div key={grade.value} className="grade-item">
        <span>{grade.title}</span>
        <Handle
          type="source"
          position={Position.Right}
          id={`grade-${grade.value}`}
          className="handle"
          style={{ backgroundColor: "#3b82f6", right: "-6px" }}
        />
      </div>
    ))}
    <div className="any-answer">
      <span>Any answer</span>
      <Handle
        type="source"
        position={Position.Bottom}
        id="any-answer"
        className="handle"
        style={{ backgroundColor: "#22c55e", bottom: "-6px" }}
      />
    </div>
  </div>
));

interface EmergencyNodeProps {
  data: { emergency: string };
}

const EmergencyNode: React.FC<EmergencyNodeProps> = memo(({ data }) => (
  <div className="node emergency-node">
    <Handle
      type="target"
      position={Position.Left}
      className="handle"
      style={{ backgroundColor: "#fbbf24" }}
    />
    <div className="node-title">{data.emergency}</div>
    <div className="answer-item">
      <span>Yes</span>
      <Handle
        type="source"
        position={Position.Right}
        id="emergency-yes"
        className="handle"
        style={{ backgroundColor: "#ef4444", right: "-6px" }}
      />
    </div>
    <div className="answer-item">
      <span>No</span>
      <Handle
        type="source"
        position={Position.Right}
        id="emergency-no"
        className="handle"
        style={{ backgroundColor: "#22c55e", right: "-6px" }}
      />
    </div>
    <div className="any-answer">
      <span>All answers</span>
      <Handle
        type="source"
        position={Position.Bottom}
        id="emergency-all-answers"
        className="handle"
        style={{ backgroundColor: "#3b82f6", bottom: "-6px" }}
      />
    </div>
  </div>
));

const ReportEmergencyNode: React.FC = memo(() => (
  <div className="node report-emergency-node">
    <Handle
      type="target"
      position={Position.Left}
      className="handle"
      style={{ backgroundColor: "#dc2626" }}
    />
    <div className="node-title">Report Emergency Case</div>
  </div>
));

const nodeTypes = {
  start: StartNode,
  end: EndNode,
  question: QuestionNode,
  emergency: EmergencyNode,
  reportEmergency: ReportEmergencyNode,
};

const createInitialNodes = (): Node[] => {
  const nodes: Node[] = [
    {
      id: "start",
      type: "start",
      position: { x: 200, y: 300 },
      draggable: true,
      data: {},
    },
    {
      id: "end",
      type: "end",
      position: { x: 200, y: (surveyData.length + 1) * 600 },
      draggable: true,
      data: {},
    },
  ];

  surveyData.forEach((question, index) => {
    nodes.push({
      id: question.id,
      type: "question",
      position: { x: 200, y: (index + 1) * 600 },
      data: question,
      draggable: true,
    });

    if (question.emergency) {
      nodes.push({
        id: `${question.id}-emergency`,
        type: "emergency",
        position: { x: 600, y: (index + 1) * 600 + 100 },
        data: { emergency: question.emergency },
        draggable: true,
      });
      nodes.push({
        id: `${question.id}-report-emergency`,
        type: "reportEmergency",
        position: { x: 1000, y: (index + 1) * 600 + 100 },
        draggable: true,
        data: {},
      });
    }
  });

  return nodes;
};

const createInitialEdges = (): Edge[] => {
  const edges: Edge[] = [];

  // Connect start to first question
  edges.push({
    id: "start-edge",
    source: "start",
    target: surveyData[0].id,
    type: "smoothstep",
  });

  // Connect questions
  for (let i = 0; i < surveyData.length; i++) {
    const currentQuestion = surveyData[i];
    const nextQuestion = surveyData[i + 1];

    // Connect "Any answer" to the next question or end node
    if (nextQuestion) {
      edges.push({
        id: `${currentQuestion.id}-any-${nextQuestion.id}`,
        source: currentQuestion.id,
        target: nextQuestion.id,
        sourceHandle: "any-answer",
        type: "smoothstep",
      });
    } else {
      edges.push({
        id: `${currentQuestion.id}-any-end`,
        source: currentQuestion.id,
        target: "end",
        sourceHandle: "any-answer",
        type: "smoothstep",
      });
    }

    // Connect to emergency node if it exists
    if (currentQuestion.emergency) {
      edges.push({
        id: `${currentQuestion.id}-emergency`,
        source: currentQuestion.id,
        target: `${currentQuestion.id}-emergency`,
        sourceHandle: `grade-${currentQuestion.grades.length}`, // Highest grade
        type: "smoothstep",
      });

      // Connect emergency "Yes" to "Report Emergency Case"
      edges.push({
        id: `${currentQuestion.id}-emergency-yes`,
        source: `${currentQuestion.id}-emergency`,
        target: `${currentQuestion.id}-report-emergency`,
        sourceHandle: "emergency-yes",
        type: "smoothstep",
      });

      // Connect emergency "All answers" to the next question or end node
      if (nextQuestion) {
        edges.push({
          id: `${currentQuestion.id}-emergency-all-${nextQuestion.id}`,
          source: `${currentQuestion.id}-emergency`,
          target: nextQuestion.id,
          sourceHandle: "emergency-all-answers",
          type: "smoothstep",
        });
      } else {
        edges.push({
          id: `${currentQuestion.id}-emergency-all-end`,
          source: `${currentQuestion.id}-emergency`,
          target: "end",
          sourceHandle: "emergency-all-answers",
          type: "smoothstep",
        });
      }
    }
  }

  return edges;
};

const SurveyFlow: React.FC = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(createInitialNodes());
  const [edges, setEdges, onEdgesChange] = useEdgesState(createInitialEdges());

  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <div style={{ width: "100%", height: "90vh" }}>
      <style>{styles}</style>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          // fitView
          minZoom={0.1}
          maxZoom={1.5}
          connectionMode={ConnectionMode.Loose}
        >
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export default SurveyFlow;
