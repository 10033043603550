import { Refine } from "@pankod/refine-core";
import { Authenticator } from "@aws-amplify/ui-react";
import routerProvider from "@pankod/refine-react-router-v6";
import { Title, Header, Layout, Sider } from "components/layout";
import amplifyDataProvider from "providers/dataProviderRest";
import { components } from "components/login";
import { createContext, useState } from "react";
import { resources } from "resources";
import { ErrorResult } from "components/error";
import Analytics from "@aws-amplify/analytics";
import axios from "axios";

import "styles/antd.less";
import ChatInterface from "components/chat";

export const UserDataContext = createContext<any>({});

function App() {
  const [isChatVisible, setChatVisible] = useState(false);
  //className="amplify-container"
  return (
    <Authenticator components={components} hideSignUp>
      {({ signOut, user }) => {
        const { list, isAdmin, groups } = resources(user);
        return (
          <UserDataContext.Provider value={user}>
            <Refine
              options={{ warnWhenUnsavedChanges: true, disableTelemetry: true }}
              routerProvider={routerProvider}
              dataProvider={amplifyDataProvider()}
              catchAll={<ErrorResult />}
              resources={list}
              Title={Title}
              Header={() => (
                <Header
                  user={user}
                  signout={signOut}
                  setChatVisible={setChatVisible}
                  isChatVisible={isChatVisible}
                />
              )}
              Sider={() => (isAdmin ? <Sider groups={groups} /> : <></>)}
              Layout={(props) => (
                <Layout
                  {...props}
                  setChatVisible={setChatVisible}
                  isChatVisible={isChatVisible}
                />
              )}
            />
          </UserDataContext.Provider>
        );
      }}
    </Authenticator>
  );
}

export default App;
