// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import "amazon-connect-streams";
import React, { memo, useRef, useEffect } from "react";
// import { CONNECT_NAME, genLogger } from "../lib";

const name = "ConnectCCP";
const CONNECT_NAME = "hurone-test";

const ConnectCCP = () => {
  const ref = useRef<any>();

  useEffect(() => {
    try {
      if (typeof window === "undefined") throw new Error("window missing");
      if (typeof window.connect === "undefined")
        throw new Error("global connect missing");

      window.connect.core.initCCP(ref.current, {
        ccpUrl: `https://gukiza-demo-eu-west2.my.connect.aws/ccp-v2/softphone`,
        loginPopup: true,
        loginPopupAutoClose: true,
        softphone: { allowFramedSoftphone: true },
      });

      const phoneNumber = '1234567890'; // Replace with your desired phone number
        const phoneNumberInput = ref.current.querySelector('.softphone-number');
        if (phoneNumberInput) {
          phoneNumberInput.value = phoneNumber;
        }
    } catch (e) {}
  }, []);

  return (
    <div
      ref={ref}
      style={{ width: "100%", height: "100%", minHeight: 480, minWidth: 400 }}
      // style={{ minWidth: 400, minHeight: 480 }}
    />
  );
};

export default memo(ConnectCCP);
