import {
  useTable,
  List,
  Table,
  Typography,
  Button,
  Icons,
  DateField,
  Tooltip,
  TextField,
  Form,
  Space,
  Drawer,
} from "@pankod/refine-antd";
import { useCreate, useList, useOne } from "@pankod/refine-core";
import GroupMessage from "pages/patients/forms/groupMessage";
import { useContext, useState } from "react";
import dayjs from "dayjs";
import { UserDataContext } from "App";
import { toFullName } from "utils/usernameTransform";

const { Paragraph } = Typography;
const { PlusOutlined, ArrowLeftOutlined } = Icons;

function mustacheToValue(str: string, output: string) {
  return str.replace(/\{\{(.+?)\}\}/g, function (match) {
    return output;
  });
}

export const MessagesTabPane = (props: any) => {
  const { patientID , patientFullName} = props;
  const user = useContext(UserDataContext);
  const [form] = Form.useForm();
  const { mutate } = useCreate();
  const [messageDrawerVisible, setMessageDrawerVisible] =
    useState<boolean>(false);

  const messagesQuery: any = useList({
    resource: "messages",
    config: {
      filters: [
        {
          field: "patientsID",
          operator: "eq",
          value: patientID,
        },
      ],
    },
    queryOptions: {
      enabled: patientID ? true : false,
    },
  });

  const patientsQuery: any = useOne({
    resource: "patients",
    id: patientID,
  });

  const selectedPatients = [patientsQuery?.data?.data]?.map?.((patient: any) => {
    return {
      fullName: patient?.fullName,
      phoneNumber: `${patient?.phoneNumber?.dialCode}${patient?.phoneNumber?.number}`,
    };
  });

  const onMessageDrawerClose = () => {
    form.resetFields();

    setMessageDrawerVisible(false);
  };

  return (
    <List
      breadcrumb={false}
      headerProps={{
        title: "Messages",
        extra: (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setMessageDrawerVisible(true);
            }}
          >
            New Message
          </Button>
        ),
      }}
    >
      <Table
        dataSource={messagesQuery?.data?.data}
        rowKey="id"
        pagination={{ position: ["bottomCenter"] }}
        loading={messagesQuery?.data?.isLoading}
      >
        <Table.Column
          dataIndex="createdAt"
          title="Date sent"
          render={(val) => (
            <Tooltip
              title={
                <DateField value={val} format="LLL" style={{ color: "#fff" }} />
              }
              color="#007787"
            >
              <DateField value={val} format="DD/MM/YYYY" />
            </Tooltip>
          )}
          defaultSortOrder="descend"
          sorter={(a: any, b: any) =>
            dayjs(a?.createdAt).unix() - dayjs(b?.createdAt).unix()
          }
        />
        <Table.Column
          dataIndex="createdBy"
          title="Sent By"
          render={(val) => <TextField value={toFullName(val)} />}
        />
        <Table.Column
          dataIndex="body"
          title="Message"
          render={(val) => (
            <div
              style={{
                wordWrap: "break-word",
                wordBreak: "break-word",
                maxWidth: "600px",
              }}
            >
              <Paragraph
                ellipsis={{
                  rows: 1,
                  expandable: true,
                }}
              >
                {mustacheToValue(val, patientFullName)}
              </Paragraph>
            </div>
          )}
        />
      </Table>
      <Drawer
        closeIcon={<ArrowLeftOutlined />}
        width="50vw"
        title="New Message"
        visible={messageDrawerVisible}
        onClose={() => setMessageDrawerVisible(false)}
        footer={
          <Space style={{ margin: 16 }} size={16}>
            {" "}
            <Button
              type="primary"
              style={{ width: "180px" }}
              onClick={() => {
                form?.validateFields().then((val) => {
                  mutate(
                    {
                      resource: "messages",
                      values: {
                        ...val,
                        selectedPatients,
                        createdBy: user?.username,
                      },
                    },
                    {
                      onSuccess: () => {
                        onMessageDrawerClose();
                      },
                    }
                  );
                });
              }}
            >
              Send Message
            </Button>
            <Button style={{ width: "180px" }} onClick={onMessageDrawerClose}>
              Cancel
            </Button>
          </Space>
        }
      >
        <Form form={form} layout="vertical">
          <GroupMessage hiddenRecepients recepients={[patientID]} form={form} />
        </Form>
      </Drawer>
    </List>
  );
};
