import React, { useContext, useState } from "react";
import { List, ShowButton } from "@pankod/refine-antd";
import { Button, Space, Table, Input, Tooltip, Tag, Drawer } from "antd";
import {
  EyeOutlined,
  PhoneOutlined,
  ProfileOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useList, useShow } from "@pankod/refine-core";
import { UserDataContext } from "App";
import { HighlightedSummary } from "components/highlightedSummary";

interface EmergencyRecord {
  id: string;
  key: string;
  patientId: string;
  fullName: string;
  diagnosis: string;
  reason: string;
  dateReported: string;
  summary: string;
  emergencyLevel: "Critical" | "High" | "Medium" | "Low";
}

const EmergencyTable: React.FC<any> = (props: any) => {
  const { onShow, onCall, onSchedule } = props;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [drawerContent, setDrawerContent] = useState<string>("");
  const [selectedFullName, setSelectedFullName] = useState<any>(null)

  const handleOpenDrawer = (content: string) => {
    setDrawerContent(content);
    setIsDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setDrawerContent("");
  };
  const [filteredData, setFilteredData] = useState<EmergencyRecord[]>([
    {
      id: "cd5c18a5-9f58-4afd-b59a-deb5e3f1b2d1",
      key: "1",
      patientId: "0034211",
      fullName: "Evelyn Smith",
      diagnosis: "Infiltrative ductal carcinoma",
      reason:
        "Patient reported Severe SHORTNESS OF BREATH, Occasionally OFTEN ARM OR LEG SWELLING, Occasionally OFTEN POUNDING OR RACING HEARTBEAT PALPITATIONS.",
      dateReported: "2024-12-26 14:30",
      emergencyLevel: "High",
      summary: `The patient is a 42-year-old female, after a diagnostic workup, she was diagnosed with Infiltrative ductal carcinoma cancer, stage 2, Triple positive (ER, PR and HER-2 positive) condition.

She underwent Breast conservative surgery a few weeks ago, followed by a chemotherapy regimen starting immediately after the surgery, completing four cycles over a span of approximately two months. She began targeted therapy with Trastuzumab a month ago and has completed two out of the planned six cycles.

She has a history of hypertension and has experienced symptoms for approximately two months. She had a significant improvement in her condition after the surgery.

Over the past week, she reported a recurrence of severe shortness of breath, occasional arm or leg swelling, and occasional pounding or racing heartbeat palpitations, including severe impact on her daily activities.`,
    },
    {
      id: "ed5c18a5-9f58-4afd-b59a-deb5e3f1b2d1",
      key: "3",
      patientId: "0026399",
      fullName: "Francis Johnson",
      diagnosis: "Lobular carcinoma",
      reason:
        "Patient reported Severe SHORTNESS OF BREATH, Occasionally OFTEN ARM OR LEG SWELLING, Occasionally OFTEN POUNDING OR RACING HEARTBEAT PALPITATIONS.",
      dateReported: "2024-12-25 18:45",
      emergencyLevel: "Low",
      summary: `The patient is a 77-year-old female who presented two months ago with symptoms of breast inflammation. After a diagnostic workup, she was diagnosed with inflammatory breast cancer, stage 2, HER-2 positive and ER and PR negative condition.
She underwent modified radical mastectomy two months ago, followed by a Carboplatin Paclitaxel regimen starting two months ago, completing 2 cycles over a span of approximately 6 weeks. She began targeted therapy with Trastuzumab (Herceptin) two months ago and has completed 2 out of the planned 6-8 cycles.
She has a history of hypertension and has experienced symptoms for approximately two months. She had her first mammogram at the age of 50.
Over the past week, she reported severe shortness of breath, occasional arm or leg swelling, and occasional pounding or racing heartbeat palpitations, including severe impact on her daily activities.`,
    },
    {
      id: "fd5c18a5-9f58-4afd-b59a-deb5e3f1b2d1",
      key: "4",
      patientId: "0036325",
      fullName: "Emma Johnson",
      diagnosis: "Inflammatory breast cancer",
      reason:
        "Patient reported very severe shortness of breath, almost constant arm or leg swelling, frequent pounding or racing heartbeat palpitations, severe dry skin, and quite a bit of numbness or tingling in hands or feet, including severe impact on her daily activities",
      dateReported: "2024-12-24 10:20",
      emergencyLevel: "Critical",
      summary: `The patient is a 35-year-old female who presented two cycles ago with lobular carcinoma cancer. After a diagnostic workup, she was diagnosed with lobular carcinoma cancer, stage 3, HER-2 positive and ER and PR negative condition.
She underwent chemotherapy two cycles ago, followed by a Docitaxel regimen starting two cycles ago, completing 2 cycles over a span of approximately 6 weeks. She began targeted therapy with Docitaxel two cycles ago and has completed 2 out of the planned 6-8 cycles.
She has a history of severe shortness of breath, arm or leg swelling, pounding or racing heartbeat palpitations, dry skin, and numbness or tingling in hands or feet and has experienced symptoms for approximately 6 weeks. She had no relevant medical milestone.
Over the past 6 weeks, she reported very severe shortness of breath, almost constant arm or leg swelling, frequent pounding or racing heartbeat palpitations, severe dry skin, and quite a bit of numbness or tingling in hands or feet, including severe impact on her daily activities.`,
    },
  ]);

  const user = useContext(UserDataContext);

  const show = useShow();

  const patients = useList({
    resource: "patients",
  });

  const emergencyData = patients?.data?.data?.filter(
    (p) =>
      p.status !== "inactive" && p.createdBy === user?.username && p?.emergency
  );

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    const filtered = dataSource.filter(
      (item) =>
        item.fullName.toLowerCase().includes(value.toLowerCase()) ||
        item.patientId.includes(value)
    );
    setFilteredData(filtered);
  };

  const getEmergencyTag = (level: EmergencyRecord["emergencyLevel"]) => {
    switch (level) {
      case "Critical":
        return <Tag color="red">Critical</Tag>;
      case "High":
        return <Tag color="volcano">High</Tag>;
      case "Medium":
        return <Tag color="orange">Medium</Tag>;
      case "Low":
        return <Tag color="green">Low</Tag>;
      default:
        return <Tag>Unknown</Tag>;
    }
  };

  const getEmergencyDescription = (
    level: EmergencyRecord["emergencyLevel"]
  ) => {
    switch (level) {
      case "Critical":
        return "Immediate medical attention required due to life-threatening symptoms.";
      case "High":
        return "Severe symptoms that could escalate without prompt intervention.";
      case "Medium":
        return "Moderate symptoms requiring observation and potential action.";
      case "Low":
        return "Mild symptoms that can be managed with routine care.";
      default:
        return "No description available.";
    }
  };

  const dataSource = filteredData;

  const columns: ColumnsType<EmergencyRecord> = [
    {
      title: "Patient ID",
      dataIndex: "patientId",
      key: "patientId",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Diagnosis",
      dataIndex: "diagnosis",
      key: "diagnosis",
      filters: [
        {
          text: "Infiltrative ductal carcinoma",
          value: "Infiltrative ductal carcinoma",
        },
        {
          text: "Inflammatory breast cancer",
          value: "Inflammatory breast cancer",
        },
        { text: "Lobular carcinoma", value: "Lobular carcinoma" },
      ],
      onFilter: (value, record) => record.diagnosis.includes(value as string),
    },
    {
      title: "Date Reported",
      dataIndex: "dateReported",
      key: "dateReported",
      render: (text) => (
        <Tooltip title={`Reported at ${text}`}>
          {new Date(text).toLocaleDateString()}
        </Tooltip>
      ),
      sorter: (a, b) =>
        new Date(a.dateReported).getTime() - new Date(b.dateReported).getTime(),
      defaultSortOrder: "descend",
    },
    {
      title: "Time Reported",
      dataIndex: "dateReported",
      key: "timeReported",
      render: (text) => {
        const time = new Date(text).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return (
          <Tooltip title={`Reported at ${text}`}>
            {time}
          </Tooltip>
        );
      },
      // sorter: (a, b) =>
      //   new Date(a.dateReported).getTime() - new Date(b.dateReported).getTime(),
      // defaultSortOrder: "descend",
    },
    
    
    // {
    //   title: "Emergency Level",
    //   dataIndex: "emergencyLevel",
    //   key: "emergencyLevel",
    //   render: (level) => getEmergencyTag(level),
    //   filters: [
    //     { text: "Critical", value: "Critical" },
    //     { text: "High", value: "High" },
    //     { text: "Medium", value: "Medium" },
    //     { text: "Low", value: "Low" },
    //   ],
    //   onFilter: (value, record) => record.emergencyLevel === value,
    //   sorter: (a, b) => {
    //     const levels = ["Low", "Medium", "High", "Critical"];
    //     return (
    //       levels.indexOf(a.emergencyLevel) - levels.indexOf(b.emergencyLevel)
    //     );
    //   },
    // },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Space>
          <Tooltip title="View Patient Details">
            <ShowButton
              hideText
              recordItemId={record?.id}
              resource="patients"
              resourceNameOrRouteName="patients"
            />
          </Tooltip>
          <Tooltip title="Call Patient">
            <Button icon={<PhoneOutlined />} onClick={onCall} />
          </Tooltip>
          <Tooltip title="Schedule Appointment">
            <Button
              icon={<CalendarOutlined />}
              onClick={() => onSchedule(record?.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <List
      headerProps={{
        title: (
          <Space size={32}>
            <span>
              <span style={{ fontSize: 20 }}>Emergency Patients</span>
            </span>
          </Space>
        ),
        extra: (
          <Space>
            <Input
              placeholder="Search by Full Name or Patient ID"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 300 }}
            />
          </Space>
        ),
      }}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: 0 }}>
              <p>{record.reason}</p>
              <p>{getEmergencyDescription(record.emergencyLevel)}</p>
              <Space>
                <Button
                  icon={<ProfileOutlined />}
                  type="primary"
                  onClick={() => {handleOpenDrawer(record.summary);
                    setSelectedFullName(record.fullName)
                  }}
                >
                  Patient Summary
                </Button>
                {/* <Button icon={<InfoCircleOutlined />} type="default">
                  All Side Effects
                </Button> */}
              </Space>
            </div>
          ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <Button type="link" onClick={(e) => onExpand(record, e)}>
              {expanded ? "Hide" : "Detail"}
            </Button>
          ),
        }}
      />
      <Drawer
        title={`${selectedFullName} - summary` || "Patient Summary"}
        placement="left"
        onClose={handleCloseDrawer}
        visible={isDrawerVisible}
        width={400}
        mask={false}
        style={{zIndex:1000000}}
      >
        <HighlightedSummary summary={drawerContent} />
      </Drawer>
    </List>
  );
};

export default EmergencyTable;
