import {
  useTable,
  List,
  Button,
  Icons,
  Space,
  Form,
  Input,
  Row,
  Col,
  Drawer,
} from "@pankod/refine-antd";

import { getSingleColorCode } from "utils/getGradeColorCode";
import adaptSurveyData, { getSeveritySimptoms } from "utils/adaptSurveyData";
import dayjs from "dayjs";
import { useCreate, useOne } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import { matchValue } from "utils/getPatientsData";
import calculateAge from "utils/getAge";
import { getResponseAI } from "utils/getResponseAI";
import jsPDF from "jspdf"; // Add this to enable PDF generation

const {
  DownOutlined,
  UpOutlined,
  MessageOutlined,
  ArrowLeftOutlined,
  PhoneOutlined,
  UndoOutlined,
  SaveOutlined,
  SettingOutlined,
  BulbOutlined,
} = Icons;

export const SummarizationTabPane = (props: any) => {
  const { patientID, title, patient, treatment } = props;

  const [prompt, setPrompt] = useState("");
  const [form] = Form.useForm();
  const { mutate } = useCreate();
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [adviceLoading, setAdviceLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { tableProps } = useTable({
    resource: "surveys",
    queryOptions: {
      refetchOnWindowFocus: true,
    },
    initialFilter: [
      {
        field: "patientID",
        operator: "eq",
        value: patientID,
      },
    ],
  });

  const surveys_: any = tableProps?.dataSource;

  const currentSurveyRecord: any = surveys_?.sort(
    (a: any, b: any) => dayjs(b?.createdAt).unix() - dayjs(a?.createdAt).unix()
  )[0];

  const getStatus = (record: any) => {
    const { answers, questionList } = record;

    if (answers?.length === 0) {
      return {
        status: "Not started",
        dateFinished: null,
      };
    }

    if (questionList?.length - 1 <= answers?.length) {
      return {
        status: "Finished",
        dateFinished: answers?.[answers?.length - 1]?.createdAt,
      };
    } else {
      return {
        status: "Ongoing",
        dateFinished: null,
      };
    }
  };

  const patientsQuery: any = useOne({
    resource: "patients",
    id: patientID,
  });

  const patientData = patientsQuery?.data?.data;
  const patients = [patientData]?.map?.((patient: any) => {
    return {
      fullName: patient?.fullName,
      phoneNumber: `${patient?.phoneNumber?.dialCode}${patient?.phoneNumber?.number}`,
    };
  });

  function getTherapyType(input: any) {
    const therapies = [
      "targetedTherapy",
      "chemotherapy",
      "chemotherapyAC",
      "bisphosphonate",
      "hormonalTherapy",
      "radiotherapy",
      "surgery",
    ];
    const result = [];

    for (const therapy of therapies) {
      if (therapy && Object.keys(input[therapy]).length > 0) {
        if (
          input[therapy].type !== undefined &&
          input[therapy].status === "Ongoing"
        ) {
          if (input[therapy].cycle)
            result.push({
              value: input[therapy].type,
              key: therapy,
              cycle: `${input[therapy].cycle.current}`,
            });
          else {
            result.push({ value: input[therapy].type, key: therapy });
          }
        }
      }
    }

    return result;
  }

  const getTherapyOutput = () => {
    const therapies = getTherapyType(treatment);

    const therapyOutput = [];
    for (const therapy of therapies) {
      const { value, key, cycle } = therapy;
      const therapyName = matchValue(value, key);
      const sufix = cycle === "1" ? "st" : "th";
      if (cycle) therapyOutput.push(`${cycle}${sufix} cycle of ${therapyName}`);
      else {
        therapyOutput.push(`${key} - ${therapyName}`);
      }
    }

    return therapyOutput.join(" along with ");
  };

  const getSurveyAnswers = () => {
    if (!currentSurveyRecord) return;
    const { questions, answers } = currentSurveyRecord;
    if (questions && answers) {
      const adapted = adaptSurveyData(questions, answers);

      const output = adapted
        .filter((item: any) => item.colorRange.orange || item.colorRange.red)
        .map((item: any) => {
          const simptoms = getSeveritySimptoms(item.title);

          return `${item.gradeTitle} ${simptoms}`;
        });

      return output.join(", ");
    } else return;
  };

  const getSurveyTemplate = () => {
    const { dateOfBirth, gender, cancerStage, biologicalSubtype, histology } =
      patient;

    const template =
      `A ${calculateAge(dateOfBirth)} year-old ${
        gender === 1 ? "Male" : "Female"
      } diagnosed with ${cancerStage} stage ${matchValue(
        histology,
        "histology"
      )} cancer - ${matchValue(
        biologicalSubtype,
        "biologicalSubtype"
      )} is on their ${getTherapyOutput()}. 
    They report: ${getSurveyAnswers()}. 
` + "";

    setPrompt(template);
    return template;
  };

  const surevysAnswers: any = getSurveyAnswers();

  useEffect(() => {
    getSurveyTemplate();
  }, [surevysAnswers]);

  const generateSummary = () => {
    setSummaryLoading(true);
    form.setFieldsValue({
      summary: "Please wait while the summary is generated ...",
    });

    getResponseAI(prompt, "summary")
      .then((response) => {
        const summaryData = JSON.parse(response?.data?.body);
        form.setFieldsValue({
          summary: summaryData?.completion || "Failed to generate summary.",
        });
      })
      .catch((error) => {
        console.error("Error generating summary:", error);
        form.setFieldsValue({
          summary: "Error generating summary.",
        });
      })
      .finally(() => {
        setSummaryLoading(false);
      });
  };

  const generateAdvice = () => {
    setAdviceLoading(true);
    form.setFieldsValue({
      advice: "Please wait while the advice is generated ...",
    });

    getResponseAI(prompt, "advice")
      .then((response) => {
        const adviceData = JSON.parse(response?.data?.body);
        form.setFieldsValue({
          advice: adviceData?.completion || "Failed to generate advice.",
        });
      })
      .catch((error) => {
        console.error("Error generating advice:", error);
        form.setFieldsValue({
          advice: "Error generating advice.",
        });
      })
      .finally(() => {
        setAdviceLoading(false);
      });
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const summary = form.getFieldValue("summary");
    const advice = form.getFieldValue("advice");

    const margin = 10; // Margin from the left
    const pageHeight = doc.internal.pageSize.height;
    const lineHeight = 7; // Line height for text
    let yPosition = 10; // Initial vertical position

    // Function to add text with automatic page breaking
    const addTextWithPageBreak = (text:any, startY:any) => {
        const lines = doc.splitTextToSize(text, 180);
        lines.forEach((line:any) => {
            if (yPosition + lineHeight > pageHeight - margin) {
                doc.addPage();
                yPosition = margin;
            }
            doc.text(line, margin, yPosition);
            yPosition += lineHeight;
        });
        yPosition += lineHeight; // Space after the section
    };

    doc.setFontSize(12);
    doc.text(`Patient Name: ${patientData?.fullName}`, margin, yPosition);
    yPosition += lineHeight;

    doc.text(`Age: ${calculateAge(patientData?.dateOfBirth)}`, margin, yPosition);
    yPosition += lineHeight;

    doc.text(`Gender: ${patientData?.gender === 1 ? "Male" : "Female"}`, margin, yPosition);
    yPosition += lineHeight * 2; // Adjust space before the next section

    // Add Summary Section
    doc.setFontSize(14);
    doc.text("Summary:", margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    addTextWithPageBreak(summary || "No summary available", yPosition);

    // Add Advice Section
    doc.setFontSize(14);
    doc.text("Advice:", margin, yPosition);
    yPosition += lineHeight;
    doc.setFontSize(12);
    addTextWithPageBreak(advice || "No advice available", yPosition);

    doc.save(`${patientData?.fullName}_report.pdf`);
};






  return (
    <List
      breadcrumb={false}
      headerProps={{
        title: "Report",
        extra: (
          <Button type="primary" onClick={() => setDrawerVisible(true)}>
            Show Report
          </Button>
        ),
      }}
    >
      <Space>
        <Button
          type="primary"
          onClick={generateSummary}
          loading={summaryLoading}
        >
          Generate Summary
        </Button>
        <Button type="primary" onClick={generateAdvice} loading={adviceLoading}>
          Generate Treatment
        </Button>
      </Space>

      <Form form={form} layout="vertical" style={{ marginTop: "16px" }}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Summary" name="summary">
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Treatment" name="advice">
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Drawer
        title="Patient Report"
        width="80%"
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        headerStyle={{ display: "flex", justifyContent: "space-between" }}
        extra={
          <Button type="primary" onClick={exportToPDF}>
            Export to PDF
          </Button>
        }
      >
        <p>
          <strong>Name:</strong> {patientData?.fullName}
        </p>
        <p>
          <strong>Age:</strong> {calculateAge(patientData?.dateOfBirth)}
        </p>
        <p>
          <strong>Gender:</strong>{" "}
          {patientData?.gender === 1 ? "Male" : "Female"}
        </p>

        <h3>Summary</h3>
        <Input.TextArea rows={10} value={form.getFieldValue("summary")} readOnly />
        <h3>Treatment</h3>
        <Input.TextArea rows={10} value={form.getFieldValue("advice")} readOnly />
      </Drawer>
    </List>
  );
};
