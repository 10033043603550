import React, { useState, useRef, useEffect } from "react";
import { CloseOutlined, ExpandOutlined, SendOutlined } from "@ant-design/icons";
import Draggable from "react-draggable";
import { Button, Space } from "antd";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { useNavigation } from "@pankod/refine-core";

interface ChatInterfaceProps {
  isVisible: boolean;
  onClose: () => void;
  showTime?: boolean;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({
  isVisible,
  onClose,
  showTime = false,
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(600);
  const [messages, setMessages] = useState([
    {
      position: "left",
      text: "Hello! How can I assist you today?",
      date: new Date(),
    },
  ]);
  const [inputValue, setInputValue] = useState("");
  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const { create } = useNavigation();
  const navigate = useNavigate()

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;

    const userMessage = {
      position: "right",
      text: inputValue,
      date: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInputValue("");

    // Bot response after 1 second
    setTimeout(() => {
      const botMessage = {
        position: "left",
        text:
          "Can you tell me more about what you need? Or choose one of the options below:",
        date: new Date(),
        options: ["Onboard new patient", "Get insights", "Go to Dashboard"],
      };
      setMessages((prev) => [...prev, botMessage]);
    }, 1000);
  };

  const handleOptionClick = (option: string) => {
    console.log(`Option clicked: ${option}`);

    const userMessage = {
        position: "right",
        text: option,
        date: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);

    setTimeout(() => {
        let targetPage = "";
        switch (option) {
            case "Onboard new patient":
                targetPage = "/patients/create";
                break;
            case "Get insights":
                targetPage = "/insights";
                break;
            case "Go to Dashboard":
                targetPage = "/dashboard";
                break;
            default:
                targetPage = "/";
        }

        // Redirect to the target page
        navigate(targetPage);

        const botMessage = {
            position: "left",
            text: `Redirecting you to the "${option}" page. How can I assist you further?`,
            date: new Date(),
        };

        setMessages((prev) => [...prev, botMessage]);
    }, 1000);
};


  const handleResize = (e: React.MouseEvent) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = width;
    const startHeight = height;

    const onMouseMove = (event: MouseEvent) => {
      const newWidth = Math.max(startWidth - (event.clientX - startX), 400); // Minimum width
      const newHeight = Math.max(startHeight - (event.clientY - startY), 600); // Minimum height
      setWidth(newWidth);
      setHeight(newHeight);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  if (!isVisible) return null;

  return (
    <Draggable
      handle=".drag-handle"
      disabled={isFullscreen}
      onStop={(e: any) => console.log(e)}
    //   bounds={{
    //     top: 64,
    //     bottom: window.innerHeight - height,
    //     left: 0,
    //     right: window.innerWidth - width,
    //   }}
    >
      <div
        style={{
          width: isFullscreen ? "100%" : `${width}px`,
          height: isFullscreen ? "100%" : `${height}px`,
          border: "1px solid #ddd",
          borderRadius: isFullscreen ? "0" : "10px",
          backgroundColor: "#ffffff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
          position: "fixed",
          bottom: "10px",
          right: "10px",
          zIndex: 1000,
        }}
      >
        {/* Header */}
        <div
          className="drag-handle"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
            backgroundColor: "#22c5d4",
            color: "white",
            cursor: isFullscreen ? "default" : "grab",
          }}
        >
          <Space>
            {/* <img
            src={"/images/icons/bot.png"}
            alt=""
            style={{
              width: "32pxpx",
              height: "36px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            //   zIndex: 1000,
            }}/> */}
            <span></span>
          </Space>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              type="text"
              icon={<ExpandOutlined />}
              style={{ color: "white" }}
              onClick={toggleFullscreen}
            />
            <Button
              type="text"
              icon={<CloseOutlined />}
              style={{ color: "white" }}
              onClick={onClose}
            />
          </div>
        </div>

        {/* Chat Content */}
        <div
          ref={chatContainerRef}
          style={{
            padding: "10px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            overflowY: "auto",
            height: "calc(100% - 120px)", // Adjust for header and input field
          }}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
                justifyContent:
                  msg.position === "left" ? "flex-start" : "flex-end",
              }}
            >
              {msg.position === "left" && (
                <img
                  src="/images/icons/bot.png" // Replace with bot avatar URL
                  alt="Bot Avatar"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              )}
              <div
                style={{
                  alignSelf: "flex-start",
                  backgroundColor:
                    msg.position === "left" ? "#e0f7fa" : "#aef2f9",
                  color: "#000",
                  padding: "10px",
                  borderRadius: "8px",
                  maxWidth: "60%",
                  wordWrap: "break-word",
                }}
              >
                <div>{msg.text}</div>
                {showTime && (
                  <div
                    style={{
                      fontSize: "0.8em",
                      color: "#555",
                      marginTop: "5px",
                      textAlign: msg.position === "left" ? "left" : "right",
                    }}
                  >
                    {msg.date.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div>
                )}

                {//@ts-ignore
                msg.options && (
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {//@ts-ignore
                    msg.options.map((option, optIndex) => (
                      <Button
                        key={optIndex}
                        size="small"
                        style={{
                          backgroundColor: "#22c5d4",
                          color: "white",
                          border: "none",
                        }}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Input Field */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            borderTop: "1px solid #ddd",
            backgroundColor: "#f9f9f9",
          }}
        >
          <input
            type="text"
            placeholder="Type your message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSendMessage();
            }}
            style={{
              flex: 1,
              border: "1px solid #ddd",
              borderRadius: "20px",
              padding: "10px",
              outline: "none",
            }}
          />
          <Button
            type="default"
            style={{ marginLeft: "10px" }}
            icon={<SendOutlined />}
            onClick={handleSendMessage}
          />
        </div>

        {/* Resize Handler */}
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "15px",
            height: "15px",
            cursor: "nwse-resize",
            backgroundColor: "transparent",
            borderRadius: "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onMouseDown={handleResize}
        >
          <div
            style={{
              width: "10px",
              height: "2px",
              backgroundColor: "transparent",
              marginBottom: "2px",
            }}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default ChatInterface;
