import {
  IResourceComponentsProps,
  useShow,
  useList,
  useUpdate,
  useNavigation,
} from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tabs,
  Col,
  Row,
  useDrawerForm,
  Modal,
  useModal,
  Space,
} from "@pankod/refine-antd";

import dayjs from "dayjs";

import CurrentTreatmentCard from "../tabPanes/generalInformation/currentTreatmetPlan";
import UserInfoCard from "../tabPanes/generalInformation/userInfo";
import MedicalHistoryCard from "../tabPanes/generalInformation/medicalHistory";
import { useEffect, useState } from "react";
import AppointmentsCard from "../tabPanes/generalInformation/appointments";
import { MessagesTabPane } from "../tabPanes/messages";
import { AppointmentsTabPane } from "../tabPanes/appointments";
import { TreatmentTabPane } from "../tabPanes/treatmentPlans";
import { SurveyTabPane } from "../tabPanes/surveys";
import { DrawerForm } from "../drawerForm";
import CallsTabPane from "../tabPanes/calls";
import { SummarizationTabPane } from "../tabPanes/summarization";

const { Text } = Typography;
const { TabPane } = Tabs;

export const PatientShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<any>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const [selectedForm, setSelectedForm] = useState<any>("");
  const [tabKey, setTabKey] = useState<string>("1");

  useEffect(() => window.scrollTo(0, 0), []);

  const treatmentData: any = useList({
    resource: "treatments",
    // id: patientID,
    config: {
      filters: [
        {
          field: "patientID",
          operator: "eq",
          value: record?.id,
        },
      ],
    },
    queryOptions: {
      enabled: record?.id ? true : false,
    },
  });

  const currentTreatmetRecord = treatmentData?.data?.data?.sort(
    (a: any, b: any) => dayjs(b?.createdAt).unix() - dayjs(a?.createdAt).unix()
  )[0];

  const { modalProps, show, close } = useModal();

  const { mutate: updateTreatmnetStatus } = useUpdate<any>();

  const { list, push } = useNavigation();

  const {
    drawerProps: editDrawerProps,
    formProps: editFormProps,
    saveButtonProps: editSaveButtonProps,
    show: editShow,
    close: editClose,
  } = useDrawerForm({
    action: "edit",
    id: record?.id,
    resource: "patients",
    redirect: false,
  });

  const {
    drawerProps: createAppointmentsDrawerProps,
    formProps: createAppointmentsFormProps,
    saveButtonProps: createAppointmentsSaveButtonProps,
    show: createAppointmentsShow,
    close: createAppointmentsClose,
  } = useDrawerForm({
    action: "create",
    submitOnEnter: true,
    redirect: false,
    resource: "appointments",
    // id: record?.id,
  });

  const {
    drawerProps: createTreatmentDrawerProps,
    formProps: createTreatmentFormProps,
    saveButtonProps: createTreatmentSaveButtonProps,
    close: createTreatmentClose,
    show: createTreatmentShow,
  } = useDrawerForm({
    action: "create",
    submitOnEnter: true,
    redirect: false,
    resource: "treatments",
  });

  const newPlanConfirm = () => {
    updateTreatmnetStatus({
      resource: "treatments",
      id: currentTreatmetRecord?.id,
      values: {
        status: "Aborted",
        id: currentTreatmetRecord?.id,
        createdAt: currentTreatmetRecord?.createdAt,
        isCurrent: false,
      },
      metaData: {
        httpMethod: "patch",
      },
    });
    setSelectedForm("New Treatment Plan");
    close();
    createTreatmentShow();
  };

  const onAddNewTreatmentPlan = () => {
    if (currentTreatmetRecord?.status === "Ongoing") {
      show();
    } else {
      setSelectedForm("New Treatment Plan");
      createTreatmentShow();
    }
  };

  const onAddNewApointment = () => {
    setSelectedForm("New Appointment");
    createAppointmentsShow();
  };

  useEffect(() => {
    if (record?.id && treatmentData?.data?.data.length === 0) {
      setSelectedForm("New Treatment Plan");
      setTimeout(() => {
        createTreatmentShow();
      }, 100);
    }

    return () => {};
  }, [treatmentData?.data?.data, record?.id, createTreatmentShow]);

  const onTabKeyChanged = (tabKey: string) => {

    // push(`show/${record?.id}/${tabKey}`)

    if (record?.id && treatmentData?.data?.data.length === 0 && tabKey === "5") {
      setSelectedForm("New Treatment Plan");
      setTimeout(() => {
        createTreatmentShow();
      }, 100);
    }
    else {
      setTabKey(tabKey)
    }
  }

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        extra: false,
        title: "Patient's file",
        breadcrumb: undefined,
        onBack: () => list("patients"),
      }}
    >
      <Tabs defaultActiveKey="1" activeKey={tabKey} onChange={onTabKeyChanged}>
        <TabPane tab="General Information" key="1">
          <Row gutter={20}>
            <Col span={14}>
              <UserInfoCard
                onEdit={() => {
                  setSelectedForm("Basic Information");
                  editShow(record?.id);
                }}
                record={record}
              />
              &nbsp;
              <AppointmentsCard
                onHistory={() => {
                  setTabKey("3");
                }}
                onAddNew={() => {
                  setSelectedForm("New Appointment");
                  createAppointmentsShow();
                }}
                record={record}
                patientID={record?.id}
              />
            </Col>
            <Col span={10}>
              <CurrentTreatmentCard
                onEdit={() => {
                  setSelectedForm("Treatment Plan");
                  editShow(record?.id);
                }}
                onAddNew={onAddNewTreatmentPlan}
                onHistory={() => {
                  setTabKey("2");
                }}
                record={currentTreatmetRecord}
              />
              &nbsp;
              <MedicalHistoryCard
                onEdit={() => {
                  setSelectedForm("Medical History");
                  editShow(record?.id);
                }}
                record={record}
              />
            </Col>
          </Row>
        </TabPane>

        <TabPane tab="Treatment Plans" key="2">
          <Text>
            <TreatmentTabPane
              patientID={record?.id}
              onAddNew={onAddNewTreatmentPlan}
            />
          </Text>
        </TabPane>
        <TabPane tab="Appointments" key="3">
          <AppointmentsTabPane
            patientID={record?.id}
            onAddNew={onAddNewApointment}
            pagination={{ position: ["bottomCenter"] }}
          />
        </TabPane>
        <TabPane tab="Messages" key="4">
          <MessagesTabPane
            patientID={record?.id}
            patientFullName={record?.fullName}
          />
        </TabPane>
        <TabPane tab="Side Effects" key="5">
          <SurveyTabPane
            treatment={currentTreatmetRecord}
            patientID={record?.id}
            patient={record}
          />
        </TabPane>
        <TabPane tab="Conversation" key="6">
         <CallsTabPane fullName={record?.fullName}/>
        </TabPane>
        <TabPane tab="Report" key="7">
        <SummarizationTabPane
            treatment={currentTreatmetRecord}
            patientID={record?.id}
            patient={record}
          />
        </TabPane>
      </Tabs>

      {(selectedForm === "Basic Information" ||
        selectedForm === "Medical History") && (
        <DrawerForm
          selectedForm={selectedForm}
          formProps={editFormProps}
          saveButtonProps={editSaveButtonProps}
          drawerProps={editDrawerProps}
          patientID={record?.id}
          close={editClose}
          record={record}
        />
      )}

      {selectedForm === "New Appointment" && (
        <DrawerForm
          selectedForm={selectedForm}
          formProps={createAppointmentsFormProps}
          saveButtonProps={createAppointmentsSaveButtonProps}
          drawerProps={createAppointmentsDrawerProps}
          patientID={record?.id}
          close={createAppointmentsClose}
        />
      )}

      {selectedForm === "New Treatment Plan" && (
        <DrawerForm
          selectedForm={selectedForm}
          formProps={createTreatmentFormProps}
          saveButtonProps={createTreatmentSaveButtonProps}
          drawerProps={{ ...createTreatmentDrawerProps, destroyOnClose: true }}
          record={record}
          close={createTreatmentClose}
        />
      )}
      <Modal
        onOk={newPlanConfirm}
        {...modalProps}
        okText="Confirm"
        title="Confirmation"
      >
        <Space direction="vertical">
          <strong>Are you sure you want to Create a New Treatment Plan?</strong>
          <p></p>
          <span>
            The Current Treatment Plan is still <strong>going</strong>.
          </span>
          <span>
            By Confirm The Current Treatment Plan will be{" "}
            <strong>Aborted</strong> and moved to Treatment Plan History.
          </span>
        </Space>
      </Modal>
    </Show>
  );
};
