// HomePage.js
import React, { useContext, useState } from "react";
import { Card, Grid, Drawer, Col, Row, Space, Button } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Icons, List, useDrawerForm } from "@pankod/refine-antd";
import ConnectCCP from "../../components/callCenter";

import { CalendarUpcomingEvents } from "components/upcomingEvents";
import { DashboardTotalCountCard } from "components/doctorTotalCounts";
import EmergencyTable from "components/emergencyTable";
import ChatInterface from "components/chat";
import { useList } from "@pankod/refine-core";
import { UserDataContext } from "App";
import { DrawerForm } from "pages/patients/drawerForm";

const { useBreakpoint } = Grid;

//rebuild2

export const HomePage = () => {
  const screens = useBreakpoint();
  const [visible, setVisible] = useState(false);
  const [patientId, setPatientId] = useState<any>(null);
  const [drawerInfo, setDrawerInfo] = useState("");
  const [callDrawerVisible, setCallDrawerVisible] = useState(false);
  const user = useContext(UserDataContext);

  const {
    drawerProps: createAppointmentsDrawerProps,
    formProps: createAppointmentsFormProps,
    saveButtonProps: createAppointmentsSaveButtonProps,
    show: createAppointmentsShow,
    close: createAppointmentsClose,
  } = useDrawerForm({
    action: "create",
    submitOnEnter: true,
    redirect: false,
    resource: "appointments",
    // id: record?.id,
  });

  const onSchedule = (id: any) => {
    setPatientId(id);
    createAppointmentsShow();
  };

  const onCall = () => {
    setCallDrawerVisible(true);
  };

  const userFullName = user?.username
    .split(".")
    .map((s: string) => s[0].toUpperCase() + s.slice(1))
    .join(" ");

  const patients = useList({
    resource: "patients",
  });

  const appointments = useList({
    resource: "appointments",
  });

  const patientsLength = patients?.data?.data?.filter(
    (p) => p.status !== "inactive" && p.createdBy === user?.username
  ).length;

  const emergencyLength = patients?.data?.data?.filter(
    (p) =>
      p.status !== "inactive" && p.createdBy === user?.username && p?.emergency
  ).length;

  const AppointmentsLength = appointments?.data?.data?.filter(
    (p) => p.createdBy === user?.username
  ).length;

  const openDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="page-container">
      <List
        headerProps={{
          title: (
            <Space size={32}>
              <span>
                <span
                  style={{ fontSize: 24 }}
                >{`Hello, Dr. ${userFullName} `}</span>
              </span>
            </Space>
          ),
          // extra: (
          //   <Space>
          //     <Button icon={<UnorderedListOutlined />} onClick={openDrawer}>
          //       Activity list
          //     </Button>
          //   </Space>
          // ),
        }}
      >
        <Row gutter={[32, 32]}>
          <Col xs={24} sm={24} xl={8}>
            <DashboardTotalCountCard
              resource="appointments"
              isLoading={false}
              totalCount={AppointmentsLength || 0}
            />
          </Col>
          <Col xs={24} sm={24} xl={8}>
            <DashboardTotalCountCard
              resource="patients"
              isLoading={false}
              totalCount={patientsLength || 0}
            />
          </Col>
          <Col xs={24} sm={24} xl={8}>
            <DashboardTotalCountCard
              resource="emergencies"
              isLoading={false}
              totalCount={emergencyLength || 0}
            />
          </Col>
        </Row>

        <Row
          gutter={[32, 32]}
          style={{
            marginTop: "32px",
          }}
        >
          <Col
            xs={24}
            sm={24}
            xl={6}
            // style={{
            //   height: "460px",
            // }}
          >
            <CalendarUpcomingEvents />
          </Col>
          <Col
            xs={24}
            sm={24}
            xl={18}
            // style={{
            //   height: "460px",
            // }}
          >
            <Card>
              <EmergencyTable onSchedule={onSchedule} onCall={onCall} />
            </Card>
          </Col>
        </Row>

        <Drawer
          title="Activity list"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <p>{drawerInfo}</p>
        </Drawer>
      </List>
      {patientId && (
        <DrawerForm
          selectedForm="New Appointment"
          formProps={createAppointmentsFormProps}
          saveButtonProps={createAppointmentsSaveButtonProps}
          drawerProps={createAppointmentsDrawerProps}
          patientID={patientId}
          close={createAppointmentsClose}
        />
      )}

      <Drawer
        closeIcon={<Icons.ArrowLeftOutlined />}
        width="40vw"
        title="Call patient"
        visible={callDrawerVisible}
        onClose={() => setCallDrawerVisible(false)}
      >
        <ConnectCCP />
      </Drawer>
    </div>
  );
};

// export default HomePage;
