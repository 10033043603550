import {
  AntdLayout,
  Space,
  Badge,
  Menu,
  Icons,
  Dropdown,
  Avatar,
  Typography,
  Modal,
  Form,
  useModal,
  message,
} from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
import { SupportForm } from "pages/patients/forms/support";
import { FC } from "react";

const {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  CustomerServiceOutlined,
  MailOutlined,
  BellOutlined,
  BulbOutlined,
  AlertOutlined,
} = Icons;
const { Text } = Typography;

const mailMenuItems = [
  { label: "Inbox", key: "inbox" },
  { label: "Sent", key: "sent" },
  { label: "Drafts", key: "drafts" },
];

const notificationMenuItems = [
  { label: "Energency notifications go here...", key: "comments" },
  // { label: "Mentions", key: "mentions" },
  // { label: "Updates", key: "updates" },
];

const aiMenuItems = [
  { label: "Generate Insights", key: "insights" },
  { label: "AI Assistant", key: "assistant" },
  { label: "Settings", key: "settings" },
];

const items = [
  {
    label: "Support",
    key: "support",
    icon: <CustomerServiceOutlined />,
  },
  { label: "", key: "divider", type: "divider" },
  { label: "Logout", key: "logout", icon: <LogoutOutlined /> },
];

interface IHeaderProps {
  user: any;
  signout: any;
  setChatVisible?: any;
  isChatVisible?: boolean
}

export const Header: FC<IHeaderProps> = (props) => {
  const { user, signout, setChatVisible, isChatVisible } = props;
  const { modalProps, show, close } = useModal();
  const { mutate, isLoading } = useCreate();
  const [form] = Form.useForm();

  const { attributes, username } = user;
  const { email } = attributes;

  const onMenuItemClick = (event: any) => {
    const { key } = event;
    console.log(key);
    if (key === "logout") {
      signout({ global: true });
    }
    if (key === "support") {
      show();
      console.log(username);
    }
  };

  const sendMessage = () => {
    form.validateFields().then((val) => {
      mutate(
        {
          resource: "support",
          values: { ...val, username: username },
        },
        {
          onSuccess: () => {
            form.resetFields();
            message.success("The message was sent successfully");
            close();
          },
          onError: () => {
            form.resetFields();
            message.error("An error has occurred");
            close();
          },
        }
      );
    });
  };

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 24px",
        width: "100%",
        height: "64px",
        top: 0,
        background: "white",
        position: "fixed",
        zIndex: 1000,
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          src={"/images/logo/huroneLogo.png"}
          alt="HuroneAI"
          style={{
            width: "116px",
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            float: "left",
            zIndex: 1000,
          }}
        />
      </div>

      <Space size={60} align="center">
        <div onClick={() => setChatVisible(!isChatVisible)}>
          <img
            src={"/images/icons/bot.png"}
            alt=""
            style={{
              cursor: "pointer",
              width: "32pxpx",
              height: "53px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          />
        </div>
        {/* <Dropdown overlay={<Menu items={mailMenuItems} />} trigger={["click"]}>
          <Badge count={5} offset={[10, 0]}>
            <MailOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
          </Badge>
        </Dropdown> */}

        <Dropdown
          overlay={<Menu items={notificationMenuItems} />}
          trigger={["click"]}
        >
          <Badge count={3} offset={[10, 0]}>
            <AlertOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
          </Badge>
        </Dropdown>

        {/* <Dropdown overlay={<Menu items={aiMenuItems} />} trigger={["click"]}>
          <Badge dot>
            <BulbOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
          </Badge>
        </Dropdown> */}

        <Dropdown overlay={<Menu items={items} onClick={onMenuItemClick} />}>
          <Space style={{ marginLeft: "16px" }}>
            <Avatar>
              <UserOutlined onClick={() => console.log(user)} />
            </Avatar>
            <Text ellipsis strong>
              {user.attributes.email}
            </Text>
            <DownOutlined />
          </Space>
        </Dropdown>
      </Space>

      <Modal
        {...modalProps}
        title="Support"
        okText="Send Message"
        onOk={sendMessage}
        okButtonProps={{ loading: isLoading }}
      >
        <Form initialValues={{ email }} layout="vertical" form={form}>
          <SupportForm />
        </Form>
      </Modal>
    </AntdLayout.Header>
  );
};
