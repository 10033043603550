import React, { FC, useContext, useState } from "react";

import { Form, Input, DatePicker, Row, Col, Select } from "@pankod/refine-antd";
import Selects from "components/selects";
import { UserDataContext } from "App";
import dayjs from "dayjs";
import { getRange } from "utils/calculations";

interface IAppointmentsFormProps {
  patientID?: string;
}

const disabledDate = (current: any) => {
  return current && current <= dayjs().startOf("day");
};

const disabledDateTime = (current: any) => {
  if (dayjs(current).isSame(dayjs(), "day")) {
    return {
      disabledHours: () => getRange(0, 24).filter((hour) => hour < dayjs().hour()),
      disabledMinutes: () =>
        getRange(0, 60).filter((minute) => minute <= dayjs().minute()),
    };
  }
  return { disabledHours: () => [], disabledMinutes: () => [] };
};

const priorityOptions = [
  { label: "Routine", value: "Routine", color: "#28a745" },
  { label: "Priority", value: "Priority", color: "#ffc107" },
  { label: "Urgent", value: "Urgent", color: "#fd7e14" },
  { label: "Emergency", value: "Emergency", color: "#dc3545" },
];

export const AppointmentsForm: FC<IAppointmentsFormProps> = (props) => {
  const { patientID } = props;
  const user = useContext(UserDataContext);
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Location"
            name="appointmentLocation"
            rules={[
              {
                required: true,
                message: "Location is a required field.",
              },
            ]}
          >
            <Input maxLength={50} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Date"
            name="appointmentDate"
            rules={[
              {
                required: true,
                message: "Appointment date is a required field.",
              },
            ]}
          >
            <DatePicker
              showTime={{ format: "HH:mm" }}
              disabledDate={disabledDate}
              disabledTime={(current) => disabledDateTime(current)}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Reason"
            name="appointmentReason"
            rules={[
              {
                required: true,
                message: "Appointment reason is a required field.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Treatment Status" name="treatmentStatus">
            <Selects datakey="treatmentStatus" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Priority"
            name="priority"
            rules={[
              {
                required: true,
                message: "Priority is a required field.",
              },
            ]}
          >
            <Select
              options={priorityOptions.map((option) => ({
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        display: "inline-block",
                        width: 10,
                        height: 10,
                        backgroundColor: option.color,
                        borderRadius: "50%",
                        marginRight: 8,
                      }}
                    ></span>
                    {option.label}
                  </div>
                ),
                value: option.value,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Notes" name="notes">
            <Input.TextArea rows={4} maxLength={500} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="" name="patientID" initialValue={patientID} hidden>
        <Input maxLength={50} />
      </Form.Item>

      <Form.Item label="" name="createdBy" initialValue={user?.username} hidden>
        <Input maxLength={50} />
      </Form.Item>
    </>
  );
};
